






































































import debounce from 'lodash.debounce'
import Session from '../../mixins/Session'
import mixins from 'vue-typed-mixins'

import { getTextForUserLanguage } from '@simpl/core/utils/text'
import { Module, ModulePackage, Tag } from '@simpl/core/types/graphql'
import { IE } from '@simpl/core/utils'
import adapterScorm12 from '../../utils/tracking-adapters/Scorm12'
import adapterScorm2004 from '../../utils/tracking-adapters/Scorm2004'
import store from '@simpl/core/plugins/store'

export default mixins(Session).extend({
  name: 'ModulePackageViewer',

  props: {
    module: Object as () => Module,
    runId: String,
    moduleId: String
  },

  data: () => ({
    opened: false,
    requireFallback: false,
    cmi: {} as Record<string, any>
  }),

  computed: {
    modulePackage (): ModulePackage {
      const userLanguage = this.$store.state.auth.temporaryLanguage || this.$store.state.auth.user.languagecode
      const marketTags = store.state.auth?.user?.tags?.filter((tag: Tag) => tag.category!.identifier === 'market')
      const marketLanguage = store.state.auth?.domain?.markets && marketTags.length
        ? `${userLanguage}-${marketTags[0].identifier}`
        : userLanguage
      return this.module.packages!.find(p => p!.languagecode === marketLanguage) ||
        this.module.packages!.find(p => p!.languagecode === userLanguage) ||
        this.module.packages!.find(p => p!.is_master)!
    },
    moduleUrl (): string {
      return `${process.env.VUE_APP_API_URL}/${this.modulePackage.path}`
    },
    packageType (): string {
      if (this.modulePackage?.type === 'scorm_unknown') {
        console.warn('SCORM Version is unknown, falling back to v1.2')
        return 'scorm_1_2'
      }
      return this.modulePackage?.type || ''
    },
    isScorm (): boolean {
      return this.packageType.startsWith('scorm_')
    }
  },

  watch: {
    session (v: any, o: any) {
      if (!o && !!v) {
        this.openWindow()
      }
    }
  },

  methods: {
    getInitData (): Record<string, any> {
      return {
        isScorm: this.isScorm,
        type: this.packageType,
        token: this.$store.state.auth.token,
        twoFactorToken: this.$store.state.auth.twoFactorToken,
        title: getTextForUserLanguage(this.module),
        starterFile: this.modulePackage.starter_file,
        noOpenerMessage: this.$t('module.scormViewer.noOpener') as string,
        cmi: {
          ...(this.packageType === 'scorm_2004' ? this.composeScorm2004InitData() : {}),
          ...(this.packageType === 'scorm_1_2' ? this.composeScorm12InitData() : {})
        }
      }
    },

    composeScorm12InitData (): Record<string, any> {
      const authUser = this.$store.state.auth.user

      // eslint-disable-next-line camelcase
      const tracking = this.session?.tracking_status

      return adapterScorm12.toCMI(tracking, authUser)
    },

    composeScorm2004InitData (): any {
      const authUser = this.$store.state.auth.user

      const tracking = this.session?.tracking_status

      return adapterScorm2004.toCMI(tracking, authUser)
    },

    openWindow (): void {
      const features = this.$store.getters['auth/settings']('global').preferTabOverNewWindow
        ? undefined
        : `menubar=no,location=no,resizable=yes,width=${window.screen.availWidth},height=${window.screen.availHeight}`

      const win = window.open(
        '/',
        undefined,
        features
      )

      if (win) {
        win.location.href = 'about:blank'
        win.location.href = this.moduleUrl

        if (features && !IE) {
          try {
            win.moveTo(0, 0)
          } catch (e) {
            console.warn(e)
          }
        }
        this.initCommunication()
        this.opened = true
      } else {
        this.requireFallback = true
      }

      (this as any).win = win
    },

    initCommunication (): void {
      window.addEventListener('message', this.onMessage, false)
    },

    onMessage (ev: MessageEvent): void {
      if (!ev.data) return

      let event: string
      let data: any

      try {
        const parsed = JSON.parse(ev.data)
        event = parsed.event
        data = parsed.data
      } catch (_) {
        return
      }

      if (event === 'content-ready') {
        const win = (this as any).win as Window
        win.postMessage(
          JSON.stringify({ event: 'init', data: this.getInitData() }),
          '*'
        )
        if (!this.isScorm) {
          this.markSessionCompleted()
        }
      }

      if (event === 'commit') this.commitData(data)
      if (event === 'commit-immediately') this._commitData(data)
    },

    backOrClose (): void {
      if (history.length > 1) {
        this.$router.back()
      } else {
        window.close()
      }
    },

    _commitData (data: Record<string, any>) {
      if (this.packageType === 'scorm_1_2') {
        this.storeSessionTracking(adapterScorm12.toTracking(data))
      } else if (this.packageType === 'scorm_2004') {
        this.storeSessionTracking(adapterScorm2004.toTracking(data))
      }
    },

    commitData: debounce(function (this: any, data: Record<string, any>): void {
      this._commitData(data)
    }, 3000)
  },

  mounted () {
    if (this.previewMode) {
      this.openWindow()
    }
  },

  beforeDestroy () {
    window.removeEventListener('message', this.onMessage)
  }
}
)
