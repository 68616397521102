<template>
  <v-navigation-drawer
    v-model="show"
    absolute
    temporary
  >
    <template
      v-if="!$vuetify.breakpoint.xs"
      #append
    >
      <div class="d-flex justify-end">
        <v-btn
          icon
          class="ma-2"
          @click="$emit('close')"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </div>
    </template>

    <v-sheet
      tile
      class="fill-height"
    >
      <v-list
        class="py-0"
        tile
      >
        <v-list-item
          v-for="view in views"
          :key="view.key"
          :class="view.class ? view.class : undefined"
          value="true"
          @click="$emit('select', view)"
        >
          <v-list-item-content>
            <v-list-item-title>{{ view.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-sheet>
  </v-navigation-drawer>
</template>

<script>
  export default {
    name: 'TrackingVisualizationSidebar',

    props: {
      value: Boolean,
      views: Array
    },

    computed: {
      show: {
        get () {
          return this.value
        },
        set (v) {
          this.$emit('input', v)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .v-list-item {
    &.v-item--active {
      color: #fc564d !important;
      caret-color: #fc564d !important;
    }
  }
</style>
