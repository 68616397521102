import { ApexOptions } from 'apexcharts'
import deepmerge from 'deepmerge'
import { MappedTrackingDataArray } from '../types'
import { app } from '@simpl/core/init-app'

export default function parse (
  mappedData: MappedTrackingDataArray,
  colors: string[],
  properties:Record<string, any>,
  labelColor: string
): ApexOptions {
  const seriesData = mappedData?.[0].seriesData

  const coChart = {
    chart: {
      type: 'bar'
      // stacked: true,
      // stackType: '100%'
    }
  }

  const coColors = {
    fill: {
      colors: colors
    }
  }

  const coPlotOptions = {
    plotOptions: {
      bar: {
        distributed: false,
        dataLabels: {
          position: 'top'
        }
      }
    }
  }

  const coDataLabels = {
    dataLabels: {
      enabled: true,
      offsetY: 4,
      textAnchor: 'middle',
      style: {
        fontSize: '14px',
        colors: [labelColor]
      },
      formatter: function (val:string, opts:Record<string, any>) {
        const item = mappedData?.[0].seriesData?.[opts.seriesIndex]
        const matchingItemIdentifier = mappedData[0].properties?.properties?.exerciseSettings?.dropZones[opts.dataPointIndex].matchingItemIdentifier
        if (item.identifier === matchingItemIdentifier) {
          return app.$t('cms.correct')
        }
      }
    }
  }

  const coLegend = {
    legend: {
      position: 'bottom',
      horizontalAlign: 'center',
      fontSize: '16px',
      show: true,
      labels: {
        colors: '#9e9e9',
        useSeriesColors: false
      },
      markers: {
        width: 20,
        height: 20,
        strokeWidth: 0,
        strokeColor: '#fff',
        fillColors: colors,
        radius: 1,
        customHTML: undefined,
        onClick: undefined,
        offsetX: 0,
        offsetY: 0
      }
    }
  }

  const coSeries = {
    series: seriesData
  }

  const coStroke = {
    stroke: {
      width: 0
    }
  }

  const coTooltip = {
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }:
        { series:Record<string, any>, seriesIndex:number, dataPointIndex: number, w: Record<string, any> }) {
        const currentSeries = series[seriesIndex]
        let count = 0
        const value = currentSeries[dataPointIndex]
        const xLabelText = w.globals.labels[dataPointIndex]
        const yLabelText = w.config.series[seriesIndex].name
        const valueText = value === 1
          ? `${value} ${app.$t('trackingVisualization.chart.vote')}`
          : `${value} ${app.$t('trackingVisualization.chart.votes')}`

        let percentText
        for (let i = 0; i < currentSeries.length; i++) {
          count += parseInt(currentSeries[i])
        }
        const percent = 100 * value / count
        if (percent < 1) {
          percentText = '< 1%'
        } else {
          percentText = String(Math.round(percent)) + '%'
        }
        return (
          '<div class="parser-tooltip">' +
            '<div class="label">' + xLabelText + '</div>' +
            '<div class="value">' + yLabelText + ':</div>' +
            '<div class="value">' + valueText + '</div>' +
            '<div class="percent">' + percentText + '</div>' +
          '</div>'
        )
      }
    }
  }

  const coXaxis = {
    xaxis: {
      type: 'category',
      title: {
        offsetY: -15
      },
      labels: {
        style: {
          colors: labelColor
        },
        rotate: 0 // no need to rotate since hiding labels gives plenty of room
        // hideOverlappingLabels: false, // all labels must be rendered
      },
      tickAmount: properties.tickAmountData?.current
        ? properties.tickAmountData.current
        : 'dataPoints'
    }
  }

  const valuesInMultiDimensionalArray = seriesData?.length && Object.hasOwn(seriesData[0], 'data') ? seriesData.map(data => data.data) : seriesData as number[]
  const valuesInOneDimensionalArray = [].concat(...valuesInMultiDimensionalArray)
  const maxVotes = valuesInOneDimensionalArray ? Math.max(...valuesInOneDimensionalArray) : 0
  const minShownTicks = 2

  const coYaxis = {
    yaxis: {
      tickAmount: maxVotes > minShownTicks ? undefined : minShownTicks,
      max: maxVotes > minShownTicks ? undefined : minShownTicks,
      labels: {
        style: {
          colors: labelColor
        },
        formatter: function (value:number) {
          if (value !== Infinity) {
            return value.toFixed(0)
          }
        }
      }
    }
  }

  return deepmerge.all([
    coChart,
    coColors,
    coDataLabels,
    coLegend,
    coPlotOptions,
    coSeries,
    coStroke,
    coTooltip,
    coXaxis,
    coYaxis
  ])
}
