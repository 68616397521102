








































import Vue from 'vue'
import { Placement, Query, User, Session } from '@simpl/core/types/graphql'
import { LIST_SESSIONS, SESSION_REVOKED, SESSION_STARTED, SESSION_TRACKING_STORED, SESSION_UPDATED } from '@simpl/base-management/runs/graphql'
import RankingList from './ranking/RankingList.vue'
import Podium from './Podium.vue'
import { sortByNestedKeys } from '@simpl/tracking-evaluation/utils/sort'
import getOrder from '@simpl/tracking-evaluation/utils/order'
import {
  HIGHLIGHT_ONLY,
  HIGHLIGHT_AND_TABLE,
  TABLE_ONLY,
  SHARED_WITH_GAPS,
  UNIQUE,
  defaultRankingProperties
} from './config/defaultProperties'

export default Vue.extend({
  name: 'Ranking',

  components: {
    Podium,
    RankingList
  },

  props: {
    trackingVisualization: Object,
    runIdentifier: String,
    properties: Object,
    useTestData: Boolean
  },

  data () {
    return {
      placements: [] as Placement[],
      sessions: [] as Session[],
      loading: 0,
      newDataStored: false,

      /** Search and filter */
      usersCount: 1,
      usersPage: 1,
      userItemsPerPage: 9999
    }
  },

  computed: {
    highlightOnly (): string {
      return HIGHLIGHT_ONLY
    },
    highlightAndTable (): string {
      return HIGHLIGHT_AND_TABLE
    },
    tableOnly (): string {
      return TABLE_ONLY
    },

    headline (): string {
      return this.properties?.title
    },

    currentPlacements (): Placement[] {
      const placements = this.useTestData ? this.testPlacements : this.placements
      const hasGaps = this.properties.rankProperty === SHARED_WITH_GAPS
      const order = getOrder(placements, 'score', hasGaps)
      const properties = this.properties.selectedUserProperties ? this.properties.selectedUserProperties : defaultRankingProperties.userProperties
      const keys = properties.map((selectedProperty: string) => {
        const userProperty = defaultRankingProperties.userProperties.find(property => property.key === selectedProperty)
        return userProperty?.languageKeyPropertyKeyMatch.propertyKey
      })
      keys.push('completed_at') // enables sorting after task completion
      const placementWithRanks = placements.map((placement, index) => {
        const filtered = Object.keys(placement)
          .filter(key => keys.includes(key))

        const filteredPlacement = filtered.reduce((obj: Record<string, any>, key: string) => {
          obj[key] = placement[key as keyof Placement]
          return obj
        }, {})
        filteredPlacement.rank = this.properties.rankProperty === UNIQUE ? index : order[index]
        return filteredPlacement as Placement
      })
      return placementWithRanks
    },

    rankingListPlacements (): Placement[] {
      if (this.properties.rankingStyle === HIGHLIGHT_AND_TABLE) {
        return this.currentPlacements.slice(3)
      } else {
        return this.currentPlacements
      }
    },

    testPlacements (): Placement[] {
      return [{
        firstname: 'Kate',
        lastname: 'Dibiasky',
        username: 'Really Long Username With Breaks And Some Additional Text and Ellipsis At The End',
        score: 10520
      }, {
        firstname: 'Janie',
        lastname: 'Orlean',
        username: 'Really Long Username With Breaks And Some Additional Text',
        score: 9320
      }, {
        firstname: 'Randall',
        lastname: 'Dr. Mindy',
        username: 'ReallyLongUsernameWithNoBreaksAndSomeAdditionalText',
        score: 9320
      }, {
        firstname: 'Brie',
        lastname: 'Evantee',
        username: 'BEvantee',
        score: 5500
      }, {
        firstname: 'Teddy',
        lastname: 'Dr. Oglethorpe',
        username: 'Really Long Username With Breaks And Some Additional Text',
        score: 5500
      }, {
        firstname: 'Maximilian-Frederik',
        lastname: 'Schöndorf-Weißenberger',
        username: 'SchoendorfWeißenberger',
        score: 5500
      }, {
        firstname: 'Jason',
        lastname: 'Orlean',
        username: 'JOrlean',
        score: 5500
      }, {
        firstname: 'Peter',
        lastname: 'Isherwell',
        username: 'PeterIsherwell',
        score: 3000
      }, {
        firstname: 'Benedict',
        lastname: 'Drask',
        username: 'BDrask',
        score: 2500
      }, {
        firstname: 'Dan',
        lastname: 'May',
        username: 'DMay',
        score: 2100
      }, {
        firstname: 'Tomer',
        lastname: 'Grelio',
        username: 'TGrelio',
        score: 1750
      }, {
        firstname: 'Paul',
        lastname: 'Themes',
        username: 'PThemes',
        score: 1200
      }, {
        firstname: 'Robert',
        lastname: 'Tenant',
        username: 'RTenant',
        score: 900
      }, {
        firstname: 'Oliver',
        lastname: 'Alberts',
        username: 'OAlberts',
        score: 825
      }, {
        firstname: 'Daniel',
        lastname: 'Williams',
        username: 'DWilliams',
        score: 444
      }, {
        firstname: 'Nisha',
        lastname: 'De Silva',
        username: 'DeSilva',
        score: 0
      }, {
        firstname: 'Hettienne',
        lastname: 'Calder',
        username: 'HCalder',
        score: 0
      }]
    },

    firstModuleId (): string | false {
      const values = this.trackingVisualization.properties?.moduleIds || []
      if (values.length <= 0) {
        return false
      }
      return values[0]
    }
  },

  apollo: {
    placements: {
      query: LIST_SESSIONS,

      fetchPolicy: 'no-cache',

      variables (): Record<string, any> {
        const values = this.trackingVisualization.properties?.moduleIds || []
        return {
          run_id: this.runIdentifier,
          filter: {
            filterBy: [{
              name: 'module:id',
              values: values
            }]
          },
          orderByRelation: {
            relation: 'trackingStatus',
            column: 'score',
            order: 'DESC'
          },
          first: 9999
        }
      },

      update (result: Query): Session[] {
        const { total, currentPage, perPage } = result.sessions!.paginatorInfo
        this.usersCount = total
        this.usersPage = currentPage
        this.userItemsPerPage = perPage
        this.loading = 0

        this.newDataStored = false

        const sessions = result.sessions!.data || []
        const placements = sessions.map(this.remapSessionEntry)
        const sortedPlacements = this.sortPlacements(placements)
        return sortedPlacements
      },

      loadingKey: 'loading',
      // TODO JH: Update when new users get points, #Issue 490
      subscribeToMore: [{
        document: SESSION_STARTED,

        skip (): boolean {
          return !this.firstModuleId
        },

        variables (): Record<string, any> {
          return {
            run_id: this.runIdentifier,
            module_id: this.firstModuleId
          }
        },

        updateQuery (previous: Session[], { subscriptionData }: any) {
          // TODO JH: Use correct event, console.log('in SESSION_STARTED')
        }
      }, {
        document: SESSION_UPDATED,

        skip (): boolean {
          return !this.firstModuleId
        },

        variables (): Record<string, any> {
          return {
            run_id: this.runIdentifier,
            module_id: this.firstModuleId
          }
        },
        updateQuery (previous: Session[], { subscriptionData }: any) {
          // TODO JH: Use correct event, console.log('in SESSION_UPDATED')
        }
      }, {
        document: SESSION_REVOKED,

        skip (): boolean {
          return !this.firstModuleId
        },

        variables (): Record<string, any> {
          return {
            run_id: this.runIdentifier,
            module_id: this.firstModuleId
          }
        },
        updateQuery () {
          // TODO JH: Use correct event, console.log('in SESSION_REVOKED')
        }
      }, {
        document: SESSION_TRACKING_STORED,

        skip (): boolean {
          return !this.firstModuleId
        },

        variables (): Record<string, any> {
          return {
            run_id: this.runIdentifier,
            module_id: this.firstModuleId
          }
        },
        updateQuery (previous: Session[], { subscriptionData }: any) {
          // TODO JH: Use correct event, console.log('in SESSION_TRACKING_STORED')
          this.newDataStored = true
        }
      }] as any
    }
  },

  methods: {
    remapSessionEntry (entry: Session): Placement {
      const firstUser = entry.users[0]
      // TODO JH: wie gehe ich mit dem Array von Usern / Team um?
      const anonUsername = entry.data?.anonUsername

      return {
        firstname: firstUser?.firstname,
        lastname: firstUser?.lastname,
        username: firstUser?.username || anonUsername,
        score: entry.tracking_status?.score || 0,
        completed_at: entry.tracking_status?.completed_at
      }
    },
    sortUsers (users: User[]): User[] {
      if (!users) {
        return []
      }

      return users.sort((a, b) =>
        sortByNestedKeys(a, b, [{
          name: 'run_evaluation.score',
          order: 'DESC'
        }])
      )
    },
    sortPlacements (placements: Placement[]): Placement[] {
      if (!placements) {
        return []
      }
      return placements.sort((a, b) =>
        sortByNestedKeys(a, b, [{
          name: 'score',
          order: 'DESC'
        }, {
          name: 'completed_at',
          order: 'ASC'
        }, {
          name: 'username',
          order: 'ASC'
        }])
      )
    },
    refetch () {
      this.$apollo.queries.placements.refetch()
    }
  }
})
