







































































































































































































































































































































import mixins from 'vue-typed-mixins'
import { Module } from '@simpl/core/types/graphql'
import { getTextForUserLanguage } from '@simpl/core/utils'
import ModuleFormats from '@simpl/core/mixins/utils/ModuleFormats'
import { PackageFormat } from '@simpl/core/utils/package-formats'

export default mixins(ModuleFormats).extend({
  name: 'ModuleTestResults',

  props: {
    passed: Boolean,
    allQuestions: Number,
    quota: Number,
    correctlyAnswered: Number,
    canRetry: Boolean,
    score: Number,
    module: Object as () => Module
  },

  computed: {
    keyVisual (): string | undefined | null {
      return this.module?.keyvisual?.url
    },
    moduleContentType (): PackageFormat {
      return this.getModuleFormat(this.module)!
    },
    correctlyAnsweredPercentage (): number {
      return (this.correctlyAnswered / this.allQuestions) * 100
    },
    quotaQuestionNumber (): number {
      return Math.round((this.quota / 100) * this.allQuestions)
    }
  },

  methods: {
    getTextForUserLanguage,
    complete () {
      this.$emit('completed')
    },
    review () {
      this.$emit('review')
    },
    retry () {
      this.$emit('retry')
    }
  }
})
