import { StoreSessionTrackingInput, User } from '@simpl/core/types/graphql'

export default interface BaseAdapter {
  toCMI (tracking: StoreSessionTrackingInput | undefined | null, user: User): Record<string, any>

  toTracking (cmi: Record<any, any>): Omit<StoreSessionTrackingInput, 'token'>
}

export function convertArraysToNumericObject (cmi: Record<string, any>) {
  const copy = { ...cmi }

  for (const key in copy) {
    if (Array.isArray(copy[key])) {
      copy[key] = copy[key].reduce((a: Record<string, any>, v: any, index: number) => {
        a[String(index)] = v
        return a
      }, {})
    }
  }

  return copy
}
