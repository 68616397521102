































































































import mixins from 'vue-typed-mixins'
import GenericKeyvisuals from '../../runs/mixins/GenericKeyvisuals'
import ImageUpload from '@simpl/core/components/uploader/ImageUpload.vue'
import { Module, File as GraphQLFile } from '@simpl/core/types/graphql'
import { getKeyvisual, hasCustomKeyvisuals, isGeneric, isFallback } from '../../utils/keyvisual'

export default mixins(GenericKeyvisuals).extend({
  name: 'EditKeyvisual',

  components: { ImageUpload },

  model: {},

  props: {
    value: Boolean,
    module: Object as () => Module,
    currentKeyvisualId: String,
    keyvisualUrl: String
  },

  data () {
    return {
      apiUrl: process.env.VUE_APP_API_URL || '',

      selectedKeyvisual: null! as GraphQLFile | undefined,
      selectOption: 'generic'
    }
  },

  computed: {
    show: {
      get (): boolean {
        return this.value
      },
      set (v: boolean) {
        this.$emit('input', v)
      }
    },
    selectFromGallery (): boolean {
      return this.selectOption === 'generic'
    },
    selectOptions (): Record<string, any>[] {
      return [{
        text: this.$t('run.edit.selectFromGallery'),
        value: 'generic'
      }, {
        text: this.$t('run.edit.uploadKeyvisual'),
        value: 'custom'
      }]
    },
    selectedKeyvisualUrl (): string | undefined | null {
      return (!isGeneric(this.selectedKeyvisual!)) ? this.keyvisualUrl : ''
    }
  },

  watch: {
    genericKeyvisuals (v) {
      this.selectedKeyvisual = v.find((file: GraphQLFile) => file.id === this.currentKeyvisualId)
    },
    currentKeyvisualId (v) {
      if (this.genericKeyvisuals) {
        this.selectedKeyvisual = this.genericKeyvisuals.find((file: GraphQLFile) => file.id === v)
      }
    },
    selectedKeyvisual (v) {
      if (!v) {
        this.selectOption = 'custom'
        return
      }

      this.$emit('change', v)
    }
  }
})
