





















import Vue from 'vue'
import { TranslateResult } from 'vue-i18n'
import StructureItem from './StructureItem.vue'

export default Vue.extend({
  components: {
    StructureItem
  },

  props: {
    children: {
      type: Array,
      default: () => []
    },
    open: Boolean
  },

  computed: {
    title (): TranslateResult {
      return this.$t('trackingVisualization.chart.xAxis')
    }
  },

  methods: {
    addCategory () {
      this.$emit('input', { type: 'addCategory' })
    },

    update (v:Record<string, any>) {
      this.$emit('input', {
        type: 'updateText',
        text: v.text,
        index: v.index
      })
    }
  }
})
