import BaseAdapter, { convertArraysToNumericObject } from './BaseAdapter'
import { SessionTrackingStatus, StoreSessionTrackingInput, User } from '@simpl/core/types/graphql'
import i18n from '@simpl/core/plugins/i18n'

const STATUS_NOT_STARTED = 'not_started'
const STATUS_WORKING = 'working'
const STATUS_COMPLETED = 'completed'
const STATUS_PASSED = 'passed'
const STATUS_FAILED = 'failed'

export class Scorm2004 implements BaseAdapter {
  toCMI (tracking: SessionTrackingStatus | undefined | null, user: User): Record<string, any> {
    const trackingData = convertArraysToNumericObject(tracking?.data || {})

    return {
      ...trackingData,
      learner_id: user.id,
      learner_name: i18n.t('core.global.fullname', [user.firstname, user.lastname]) as string
    }
  }

  toTracking (cmi: Record<any, any>): Omit<StoreSessionTrackingInput, 'token'> {
    return {
      tracking_status: {
        bookmark: cmi.location || '',
        status: scormStatusToTrackingStatus(cmi.completion_status, cmi.success_status),
        objectives: JSON.stringify(cmi.objectives),
        score: +cmi.score.raw,
        progress: Math.round(calculateFloatStore(cmi.score)),
        data: JSON.stringify(cmi)
      }
    }
  }
}

function calculateFloatStore (score: { min?: string, max?: string, raw?: string }): number {
  const min = parseFloat(score.min || '0')
  const max = parseFloat(score.max || '100')
  const raw = parseFloat(score.raw || '0')

  return (raw - min) / (max - min) * 100
}

function scormStatusToTrackingStatus (completionStatus: string, successStatus: string) {
  switch (completionStatus) {
    case 'not attempted':
      return STATUS_NOT_STARTED
    case 'completed':
      if (successStatus === 'passed') {
        return STATUS_PASSED
      } else if (successStatus === 'failed') {
        return STATUS_FAILED
      }

      return STATUS_COMPLETED
    default:
      return STATUS_WORKING
  }
}

const adapterScorm2004 = new Scorm2004()

export default adapterScorm2004
