










import Vue from 'vue'

export default Vue.extend({
  name: 'SettingsTitle',

  props: {
    title: String
  },

  data () {
    return {
      timer: null as any
    }
  },

  computed: {
    internalTitle: {
      get (): string {
        return this.title
      },
      set (v: string) {
        clearTimeout(this.timer)
        this.timer = setTimeout(() => this.$emit('input', { title: v }), 50)
      }
    }
  }
})
