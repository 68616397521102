

















import Vue, { Component } from 'vue'

import { RUN_DASHBOARD } from '../../runs/graphql'
import { Run, Module, Query } from '@simpl/core/types/graphql'

import ModulePDFViewer from '../views/viewer/ModulePDFViewer.vue'
import ModuleVideoViewer from '../views/viewer/ModuleVideoViewer.vue'
import ModuleDownloadViewer from '../views/viewer/ModuleDownloadViewer.vue'
import ModuleExternalViewer from '../views/viewer/ModuleExternalViewer.vue'
import ModulePackageViewer from '../views/viewer/ModulePackageViewer.vue'
import ModuleCMSViewer from '../views/viewer/ModuleCMSViewer.vue'
import ModuleTestViewer from './viewer/ModuleTestViewer.vue'
import { GET_MODULE } from '../graphql'
import { getTextForUserLanguage } from '@simpl/core/utils/text'
import { MUTATIONS } from '@simpl/cms/store/consts'

export default Vue.extend({
  name: 'ModuleView',

  async beforeRouteLeave (to: any, _from: any, next: (target?: any) => void) {
    if (!this.showConfirmHint) {
      next()
      return
    }

    const userFeedback = await this.$confirm({
      message: this.$t('module.pdfViewer.confirmHint'),
      buttons: [{
        text: this.$t('core.global.yes'),
        type: 'outlined',
        answer: false
      }, {
        text: this.$t('core.global.no'),
        answer: true
      }]
    })

    if (!userFeedback) {
      next()
      return
    }

    next(false)
  },

  props: {
    moduleIdentifier: String,
    runIdentifier: String,
    moduleId: String
  },

  breadcrumbs (): any[] {
    const text = this.$router.currentRoute.meta?.breadcrumbs?.text
    const breadcrumbs = []

    if (text) {
      breadcrumbs.push({
        text,
        to: null
      })
    }

    const { module, run } = this as any

    if (module) {
      breadcrumbs.unshift({
        title: getTextForUserLanguage(module),
        to: null
      })

      if (this.$router.currentRoute.path.substr(-7) === 'preview') {
        return breadcrumbs
      }

      if (!run) {
        breadcrumbs.unshift({
          title: this.$t('module.global.headline'),
          to: '/contents'
        })
      }
    }

    if (run) {
      breadcrumbs.unshift({
        title: getTextForUserLanguage(run),
        to: `/${run.type}/${run.identifier}`
      })
    }

    return breadcrumbs
  },

  data: () => ({
    loading: 0,
    run: null as Run | null | undefined,
    module: null as Module | null | undefined,
    showConfirmHint: false
  }),

  computed: {
    component (): Component | null {
      if (!this.module) return null
      switch (this.module.type) {
        case 'package':
          return ModulePackageViewer
        case 'media':
          return ModuleVideoViewer
        case 'pdf':
          return ModulePDFViewer
        case 'download':
          return ModuleDownloadViewer
        case 'external':
          return ModuleExternalViewer
        case 'simpl':
          return ModuleCMSViewer
        case 'test':
          return ModuleTestViewer

        default:
          return null
      }
    }
  },

  apollo: {
    module: {
      query: GET_MODULE,

      skip (): boolean {
        return !this.moduleId
      },

      variables (): object {
        return {
          id: this.moduleId
        }
      },

      update (result: Query): Module | null {
        if (!result.module) {
          this.$router.replace('/404')
          return null
        }
        this.$store.commit(`cms/${MUTATIONS.SET_MASTER_LANGUAGE_CODE}`, result.module!.languagecode)
        // DEPRECATED: (?)
        /*
        if (!this.$store.state.auth.temporaryLanguage) {
          this.$store.commit(`auth/${AUTH_MUTATIONS.SET_TEMPORARY_LANGUAGE}`, { temporaryLanguage: this.$store.state.auth.user?.languagecode || null })
        }
        */
        return result.module!
      },

      loadingKey: 'loading'
    },
    run: {
      query: RUN_DASHBOARD,

      skip (): boolean {
        return !!this.moduleId
      },

      variables (): object {
        return {
          slug: this.runIdentifier
        }
      },

      update (result: Query): Run {
        const run = result.runDashboard!.run! as Run
        this.module = run?.modules?.find(m => m!.identifier === this.moduleIdentifier)
        this.$store.commit(`cms/${MUTATIONS.SET_MASTER_LANGUAGE_CODE}`, this.module!.languagecode)
        // DEPRECATED: (?)
        /*
        if (!this.$store.state.auth.temporaryLanguage) {
          this.$store.commit(`auth/${AUTH_MUTATIONS.SET_TEMPORARY_LANGUAGE}`, { temporaryLanguage: this.$store.state.auth.user?.languagecode || null })
        }
        */
        return run
      },

      loadingKey: 'loading'
    }
  }
}
)
