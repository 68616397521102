















import Vue from 'vue'

export default Vue.extend({
  name: 'SettingsNumericCategories',

  props: {
    tickAmountData: {
      type: Object,
      default: () => ({
        default: Number,
        current: Number,
        numOfSteps: Number
      })
    }
  },

  data () {
    return {
      inputHeight: 28
    }
  },

  computed: {
    internalNumOfSteps: {
      get (): number {
        return this.tickAmountData.numOfSteps
      }
    },
    internalTickAmount: {
      get (): number {
        return this.tickAmountData.current
      },
      set (v: number) {
        this.$emit('input', { type: 'updateTickAmount', value: v })
      }
    },
    maxSteps: {
      get (): number {
        return this.tickAmountData.numOfSteps < 25 ? this.tickAmountData.numOfSteps : 25
      }
    }
  }
})
