




















































































import mixins from 'vue-typed-mixins'
import Session from '../../mixins/Session'
import { ContentTree, IAdapterCMS } from '@simpl/cms/types'
import { Query } from '@simpl/core/types/graphql'
import { GET_CONTENT_TREE, VIEW_CONTENT_TREE } from '@simpl/cms/graphql'
import normalizeTree from '@simpl/cms/utils/normalize-tree'
import StoreAdapter from '@simpl/cms-components/adapters/StoreAdapter'
import { mapMutations } from 'vuex'
import { getTreeContent } from '@simpl/cms/utils/tree-operations'
import { getTextForUserLanguage } from '@simpl/core/utils'
import CMSRenderer from '@simpl/cms/components/CMSRenderer.vue'
import ModuleTestResults from '../ModuleTestResults.vue'
import { shuffle } from '@simpl/core/utils/core'

export type ContentTreeExtended = ContentTree & {
  numberOfQuestions?: number
}

export default mixins(Session).extend({
  name: 'ModuleTestViewer',

  components: { ModuleTestResults, CMSRenderer },

  data () {
    return {
      loading: 0,

      adapter: null! as IAdapterCMS,
      content: null! as ContentTree,
      noContent: false,

      selectedQuestionIdIndex: null! as number,
      questionEvaluated: false,
      visitedSites: [] as number[],
      evaluation: {} as any,
      questionIds: [] as number[]
    }
  },

  computed: {
    chapters (): ContentTreeExtended[] {
      if (!this.content) return []

      const chapters: ContentTreeExtended[] = []
      this.content.children.forEach((child: ContentTree) => {
        if (child.type === 'chapter') {
          chapters.push({
            ...child,
            numberOfQuestions: this.module.properties?.numberOfQuestions
              ? this.module.properties?.numberOfQuestions[child.id]
              : undefined
          })
        }
      })

      return chapters
    },
    selectedQuestionId (): number {
      return this.questionIds[this.selectedQuestionIdIndex]
    },
    selectedContent (): ContentTree | undefined | null {
      return !this.content || !this.selectedQuestionId ? null : getTreeContent(this.content, this.selectedQuestionId)
    },
    allActiveComponentsCompleted (): boolean {
      return this.previewMode || this.$store.getters['cms/activeComponentsCompleted']
    },
    allActiveComponentsPoints (): number {
      return this.$store.getters['cms/activeComponentsPoints']
    },
    lastPageSelected (): boolean {
      return !!this.questionIds.length && (this.selectedQuestionIdIndex + 1 > this.questionIds.length ||
        (this.questionIds.length === 1 && this.selectedQuestionIdIndex > 0))
    },
    lastPageInPreview (): boolean {
      return !!this.questionIds.length && this.selectedQuestionIdIndex + 1 === this.questionIds.length && this.previewMode
    },
    nextEnabled (): boolean {
      return this.allActiveComponentsCompleted || this.hasEvaluation(this.selectedQuestionId) || this.previewMode
    },
    quota (): number {
      return parseInt(this.module.properties?.quota)
    },
    totalCorrect (): number {
      if (!this.sessionEvaluation) return 0

      let correctAnswers = 0

      Object.keys(this.sessionEvaluation).forEach(questionId => {
        if (this.sessionEvaluation[questionId]) correctAnswers++
      })

      return correctAnswers
    },
    percentage (): number {
      if (!this.sessionEvaluation) return 0

      return this.totalCorrect / (Object.keys(this.sessionEvaluation).length / 100)
    },
    passed (): boolean {
      return !this.quota || this.percentage >= this.quota
    },
    canRetry (): boolean {
      return !this.passed && this.attempt < (this.module.properties?.attemptMax || 1)
    },
    shuffle (): boolean {
      return !!this.module.properties?.shuffle
    }
  },

  watch: {
    content (v, o) {
      if (o) return

      this.initQuestionIds()

      if (this.previewMode) {
        this.selectedQuestionIdIndex = 0
        return
      }

      this.visitedSites = this.sessionVisitedSites
      this.evaluation = this.sessionEvaluation

      this.selectedQuestionIdIndex = this.bookmark
        ? this.questionIds.indexOf(parseInt(this.bookmark))
        : this.sessionEvaluated ? this.questionIds.length : 0
    },
    selectedQuestionIdIndex: {
      handler (v, o) {
        if (v === undefined || this.noContent || this.previewMode) return
        if (o === undefined || v < o || this.sessionEvaluated) {
          this.adapter.setCurrentObjective(this.selectedQuestionId!)
          this.storeSessionTracking({
            tracking_status: {
              bookmark: this.selectedQuestionId ? `${this.selectedQuestionId}` : ''
            }
          })
          return
        }
        if ((v + 1 > this.questionIds.length || (this.questionIds.length === 1 && v === 1)) && !this.sessionEvaluated) {
          this.saveSessionEvaluation()
          return
        }

        this.questionEvaluated = false
        const progress = this.updateProgress(this.selectedQuestionId!)

        this.adapter.setCurrentObjective(this.selectedQuestionId!)
        this.storeSessionTracking({
          tracking_status: {
            bookmark: this.selectedQuestionId ? `${this.selectedQuestionId}` : '',
            status: 'working',
            progress: progress,
            data: JSON.stringify({
              visitedSites: this.visitedSites,
              evaluation: this.evaluation
            })
          }
        })
      }
    },
    questionIds (v) {
      if (!v.length && !!this.content) this.notifyNoContent()
    },
    allActiveComponentsCompleted (v) {
      if (!v || this.sessionEvaluated || this.previewMode) return

      const progress = this.updateProgress(this.selectedQuestionId!)

      this.evaluation[this.selectedQuestionId] = this.$store.getters['cms/activeComponentsCorrect']

      this.adapter.setCurrentObjective(this.selectedQuestionId!)
      this.storeSessionTracking({
        tracking_status: {
          score: this.trackingStatus.score
            ? this.trackingStatus.score + this.allActiveComponentsPoints
            : this.allActiveComponentsPoints,
          bookmark: this.selectedQuestionId ? `${this.selectedQuestionId}` : '',
          status: 'working',
          progress: progress,
          data: JSON.stringify({
            visitedSites: this.visitedSites,
            evaluation: this.evaluation
          })
        }
      })

      this.questionEvaluated = true
    },
    sessionEvaluated: {
      immediate: true,
      handler (v) {
        if (!v) return

        this.setShowTestEvaluation(true)
      }
    }
  },

  async created () {
    if (!this.previewMode) {
      this.adapter = new StoreAdapter()
      await this.adapter.init(this.run.id, this.module.id)
    }
    this.setEditMode(false)
    this.setTest(true)
  },

  beforeDestroy () {
    if (!this.restarting) {
      this.setTest(false)
      this.setShowTestEvaluation(false)
    }
  },

  apollo: {
    content: {
      query () {
        return this.previewMode ? GET_CONTENT_TREE : VIEW_CONTENT_TREE
      },

      skip () {
        return !this.session && !this.previewMode
      },

      variables () {
        return {
          moduleId: this.module.id
        }
      },

      update (result: Query): ContentTree | null {
        const contentTree = this.previewMode ? result.contentTree : result.viewContentTree

        if (!contentTree) {
          this.notifyNoContent()
          return null
        }

        return normalizeTree(contentTree)
      },

      error (error: Error): void {
        console.error(error)
      },
      context () {
        return this.previewMode
          ? {}
          : {
            headers: {
              simplsession: this.session.token
            }
          }
      },

      loadingKey: 'loading'
    }
  },

  methods: {
    ...mapMutations('module', ['setTest', 'setShowTestEvaluation']),
    ...mapMutations('cms', ['resetUserInput', 'setEditMode']),
    getTextForUserLanguage,

    initQuestionIds () {
      let questions = [] as ContentTreeExtended[]

      if (this.sessionQuestionIds.length) {
        const allQuestions = [] as ContentTreeExtended[]

        this.chapters.forEach(chapter => {
          allQuestions.push(...chapter.children)
        })

        this.sessionQuestionIds.forEach((id: number) => {
          const question = allQuestions.find(question => parseInt(question.id) === id)
          if (question) questions.push(question)
        })
      } else {
        this.chapters.forEach(chapter => {
          let chapterQuestions = chapter.children.slice()

          if (this.shuffle) chapterQuestions = shuffle(chapterQuestions)
          if (chapter.numberOfQuestions) chapterQuestions = chapterQuestions.slice(0, chapter.numberOfQuestions)

          questions.push(...chapterQuestions)
        })

        if (this.shuffle) {
          questions = shuffle(questions)
        }
      }

      this.questionIds = questions.map(question => parseInt(question.id))

      if (!this.sessionQuestionIds.length && !this.previewMode) {
        this.updateSession({ data: JSON.stringify({ ...this.session.data, questionIds: this.questionIds }) })
      }
    },
    updateProgress (currentSiteId: number): number {
      if (currentSiteId && !this.visitedSites.includes(currentSiteId)) {
        this.visitedSites.push(currentSiteId)
      }
      return Math.round((this.visitedSites.length / this.questionIds.length) * 100)
    },
    saveSessionEvaluation () {
      this.storeSessionTracking({
        tracking_status: {
          bookmark: this.selectedQuestionId ? `${this.selectedQuestionId}` : '',
          status: this.passed ? 'passed' : 'failed',
          progress: 100,
          data: JSON.stringify({
            visitedSites: this.visitedSites,
            evaluation: this.evaluation
          })
        }
      })
    },
    hasEvaluation (currentSiteId: number): boolean {
      if (!currentSiteId || !this.sessionEvaluation) return false

      const evaluatedSites = Object.keys(this.sessionEvaluation)
      return evaluatedSites.indexOf(currentSiteId.toString()) > -1
    },
    backToDashboard () {
      if (this.run) {
        this.$router.push(`/${this.run.type}/${this.run.identifier}`)
      } else {
        window.close()
      }
    },
    async retry () {
      this.resetUserInput()
      this.setShowTestEvaluation(false)
      await this.archiveAndRestartSession()
    },
    notifyNoContent () {
      this.noContent = true
      this.$notification.publish('bottom', {
        message: this.$t('module.cmsViewer.noContent'),
        type: 'error',
        color: 'error'
      })
    }
  }
})
