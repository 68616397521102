import { ApexOptions } from 'apexcharts'
import deepmerge from 'deepmerge'
import { MappedTrackingDataArray } from '../types'
import getAnnotations from './utils/get-annotations'
import { app } from '@simpl/core/init-app'

export default function parse (
  mappedData: MappedTrackingDataArray,
  colors: string[],
  properties:Record<string, any>,
  labelColor: string
): ApexOptions {
  let series = null as null | {x: any, y: any}[]
  const seriesData = mappedData.map((data, index) => {
    if (properties.categories) {
      series = properties.categories.map((category:string, index:number) =>
        data.seriesData && data.seriesData[index] ? data.seriesData[index] : 0
      )
    } else {
      series = data.seriesData
    }
    return {
      data: series
    }
  })

  const coAnnotations = {
    annotations: {
      xaxis: getAnnotations(mappedData[0])
    }
  }

  const coChart = {
    chart: {
      type: 'bar',
      animations: {
        enabled: true,
        easing: 'easeinout',
        dynamicAnimation: {
          enabled: true,
          speed: 450
        }
      }
    }
  }

  const coColors = {
    fill: {
      colors: colors
    }
  }

  const coDataLabels = {
    dataLabels: {
      enabled: true,
      offsetY: 4,
      textAnchor: 'middle',
      style: {
        fontSize: '14px',
        colors: [labelColor]
      },

      formatter: function (val:string, opts:Record<string, any>) {
        const properties = mappedData[0].properties

        if (properties?.tag) {
          const itemId = properties.properties?.answers?.[opts.dataPointIndex]?.id
          const solution = properties.properties?.exerciseSettings?.solution
          const isItemSolution = solution && solution === itemId
          if (isItemSolution) {
            return app.$t('cms.correct')
          }
        }
      }
    }
  }

  const coSeries = {
    series: seriesData
  }

  const coStroke = {
    stroke: {
      width: 0
    }
  }

  const coTooltip = {
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }:
        { series:Record<string, any>, seriesIndex:number, dataPointIndex: number, w: Record<string, any> }) {
        const currentSeries = series[seriesIndex]
        let count = 0
        const value = currentSeries[dataPointIndex]
        const labelText = w.globals.labels[dataPointIndex]
        // TODO @jh: remove duplicated code
        const valueText = value === 1
          ? `${value} ${app.$t('trackingVisualization.chart.vote')}`
          : `${value} ${app.$t('trackingVisualization.chart.votes')}`

        let percentText
        for (let i = 0; i < currentSeries.length; i++) {
          count += parseInt(currentSeries[i])
        }
        const percent = 100 * value / count
        if (percent < 1) {
          percentText = '< 1%'
        } else {
          percentText = String(Math.round(percent)) + '%'
        }

        let tooltipText = ''

        if (labelText === undefined) {
          tooltipText = '<div class="parser-tooltip">' +
            '<div class="value">' + valueText + '</div>' +
            '<div class="percent">' + percentText + '</div>' +
          '</div>'
        } else {
          tooltipText = '<div class="parser-tooltip">' +
            '<div class="label">' + labelText + '</div>' +
            '<div class="value">' + valueText + '</div>' +
            '<div class="percent">' + percentText + '</div>' +
          '</div>'
        }
        return tooltipText
      }
    }
  }

  const coXaxis = {
    xaxis: {
      type: 'numeric',
      labels: {
        style: {
          colors: labelColor
        },
        rotate: 0 // no need to rotate since hiding labels gives plenty of room
        // hideOverlappingLabels: false // all labels must be rendered
      },

      tickAmount: properties.tickAmountData?.current
        ? properties.tickAmountData.current
        : 'dataPoints'
    }
  }

  const seriesYValues = seriesData[0].data?.length && Object.hasOwn(seriesData[0].data[0], 'y') ? seriesData[0].data.map(data => data.y) : seriesData[0].data as {x: any, y: any}[]
  const maxVotes = seriesYValues ? Math.max(...seriesYValues) : 0
  const minShownTicks = 2

  const coYaxis = {
    yaxis: {
      tickAmount: maxVotes > minShownTicks ? undefined : minShownTicks,
      max: maxVotes > minShownTicks ? undefined : minShownTicks,
      labels: {
        style: {
          colors: labelColor
        },
        formatter: function (value:number) {
          if (value !== Infinity) {
            return value.toFixed(0)
          }
        }
      }
    }
  }

  return deepmerge.all([
    coAnnotations,
    coChart,
    coColors,
    coDataLabels,
    coSeries,
    coStroke,
    coTooltip,
    coXaxis,
    coYaxis
  ])
}
