





































import Vue from 'vue'

export default Vue.extend({
  name: 'CompactGraphTypeSelect',

  props: {
    value: String,
    isDialog: {
      type: Boolean,
      default: false
    },
    views: Array
  },

  data () {
    return {
      icons: {
        bar: 'mdi-chart-bar',
        line: 'mdi-chart-line-variant',
        pie: 'mdi-chart-pie',
        donut: 'mdi-chart-donut'
      }
    }
  },

  computed: {
    internalValue: {
      get () {
        return this.value
      }
    },
    internalViews: {
      get () {
        return this.views
      }
    }
  },

  methods: {
    isActive (view: string) {
      return view === this.internalValue
    },
    toggle (v: string) {
      if (this.value !== v) {
        this.$emit('select', v)
      }
    }
  }
})
