



























































































































import Vue from 'vue'
import { Module, Query } from '@simpl/core/types/graphql'
import { ContentTree } from '@simpl/cms/types'
import { GET_CONTENT_TREE } from '@simpl/cms/graphql'
import normalizeTree from '@simpl/cms/utils/normalize-tree'
import { getTextForUserLanguage } from '@simpl/core/utils'

export type ContentTreeExtended = ContentTree & { numberOfQuestions: number }

export default Vue.extend({
  name: 'ModuleEditTest',

  props: {
    module: Object as () => Module
  },

  data () {
    return {
      content: null! as ContentTree,
      loading: 0,
      expanded: undefined as number | undefined,

      rules: {
        max: (max: number, value: number) => {
          return (value && value <= max) || this.$t('module.editTest.questionsMaxExceeded')
        }
      }
    }
  },

  computed: {
    chapters (): ContentTreeExtended[] {
      if (!this.content) return []

      const chapters: ContentTreeExtended[] = []
      this.content.children.forEach((child: ContentTree) => {
        if (child.type === 'chapter') {
          chapters.push({
            ...child,
            numberOfQuestions: this.module.properties?.numberOfQuestions ? parseInt(this.module.properties.numberOfQuestions[child?.id]) : child.children.length
          })
        }
      })

      return chapters
    },
    quota: {
      get (): number {
        return this.module.properties.quota
      },
      set (v: number) {
        if (!this.module.properties.quota) {
          this.$set(this.module.properties, 'quota', v)
        } else {
          this.module.properties.quota = v
        }
      }
    },
    maxTries: {
      get (): number {
        return this.module.properties.attemptMax || 1
      },
      set (v: number) {
        if (!this.module.properties.attemptMax) {
          this.$set(this.module.properties, 'attemptMax', v || 1)
        } else {
          this.module.properties.attemptMax = v || 1
        }
      }
    },
    shuffleQuestions: {
      get (): boolean {
        return this.module.properties?.shuffle
      },
      set (v: boolean) {
        if (!this.module.properties.shuffle) {
          this.$set(this.module.properties, 'shuffle', v)
        } else {
          this.module.properties.shuffle = v
        }
      }
    },
    totalQuestionAmount (): number {
      if (!this.module.properties?.numberOfQuestions) return 0

      let questions = 0

      this.chapters.forEach((chapter: ContentTreeExtended) => {
        questions += chapter.numberOfQuestions
      })

      return questions
    }
  },

  apollo: {
    content: {
      query () {
        return GET_CONTENT_TREE
      },

      variables () {
        return {
          moduleId: this.module.id
        }
      },

      update (result: Query): ContentTree | null {
        const contentTree = result.contentTree

        return contentTree ? normalizeTree(contentTree) : null
      },

      error (error: Error): void {
        console.error(error)
      },

      loadingKey: 'loading'
    }
  },

  methods: {
    getTextForUserLanguage,

    getChapterSites (chapter: ContentTree): ContentTree[] {
      if (!chapter?.children?.length) return []

      const traverse = (item: ContentTree): any => {
        if (!(['site'].includes(item.type))) return false

        return {
          id: item.id,
          type: item.type,
          children: [...item.children.map(traverse)].filter(Boolean),
          data: item.data,
          texts: item.texts,
          name: getTextForUserLanguage(item)
        }
      }

      return chapter.children.map(traverse)
    },
    setNumberOfQuestions (chapterId: string, value: number) {
      if (!this.module.properties.numberOfQuestions) {
        this.$set(this.module!.properties, 'numberOfQuestions', {})
      }

      this.module!.properties.numberOfQuestions = {
        ...this.module!.properties.numberOfQuestions,
        [chapterId]: value || 0
      }
    },
    toggleExpanded (index: number) {
      this.expanded = this.expanded === index ? undefined : index
    }
  }
})
