






































import Session from '../../mixins/Session'
import mixins from 'vue-typed-mixins'

import { File, Tag } from '@simpl/core/types/graphql'
import store from '@simpl/core/plugins/store'

export default mixins(Session).extend({
  name: 'ModuleDownloadViewer',

  computed: {
    langFile (): File {
      const moduleFile = this.module.downloads![0]!
      const userLanguage = this.$store.state.auth.temporaryLanguage || this.$store.state.auth.user.languagecode
      const marketTags = store.state.auth?.user?.tags?.filter((tag: Tag) => tag.category!.identifier === 'market')
      const marketLanguage = store.state.auth?.domain?.markets && marketTags.length
        ? `${userLanguage}-${marketTags[0].identifier}`
        : userLanguage

      const langFile = (moduleFile.files || []).find(f => f!.languagecode === marketLanguage) || (moduleFile.files || []).find(f => f!.languagecode === userLanguage)

      return langFile || moduleFile.masterFile!
    }
  },

  watch: {
    session (v, o) {
      if (!o && v) {
        this.startDownload()
      }
    }
  },

  methods: {
    startDownload () {
      setTimeout(() => {
        const linkEl = this.$refs.downloadLink as HTMLElement
        linkEl.click()
      }, 1000)
    }
  },

  mounted () {
    if (this.previewMode) {
      this.startDownload()
    }
  }
}
)
