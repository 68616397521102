















import Vue from 'vue'

export default Vue.extend({
  name: 'SettingsDiagramType',

  props: {
    all: {
      type: Array as () => string[]
    },
    selected: {
      type: Array as () => string[]
    }
  },

  computed: {
    internalSelected: {
      get (): string[] {
        return this.selected
      },
      set (v: string[]) {
        this.$emit('input', v)
      }
    },
    internalAll: {
      get (): string[] {
        return this.all
      }
    }
  },

  methods: {
    isDisabled (value: string) {
      return (this.internalSelected.length === 1) && this.internalSelected.includes(value)
    }
  }
})
