











































































































































































































































































































































































































import LanguageTags from '@simpl/core/mixins/apollo/LanguageTags'
import UserGroupTags from '@simpl/core/mixins/apollo/UserGroupTags'
import StringToColor from '@simpl/core/mixins/utils/StringToColor'
import mixins from 'vue-typed-mixins'
import { Module, Tag, File as GraphQLFile } from '@simpl/core/types/graphql'
import EditKeyvisual from '../components/EditKeyvisual.vue'
import DomainMarketSelection from '@simpl/core/components/DomainMarketSelection.vue'
import { formatFileSize } from '../../utils/file'
// @ts-ignore
import { ModuleEditUpdates } from './ModuleEdit.vue'
import { getTextForLanguage, getTextForUserLanguage } from '@simpl/core/utils'
import GenericKeyvisuals from '../../runs/mixins/GenericKeyvisuals'
import ModuleFormats from '@simpl/core/mixins/utils/ModuleFormats'
import { deleteConfirm } from '@simpl/core/utils/message'
import { PackageFormat } from '@simpl/core/utils/package-formats'
import { TagWithName } from '@simpl/core/types/extended-types'

export default mixins(StringToColor, GenericKeyvisuals, ModuleFormats).extend({
  name: 'ModuleEditBasic',

  components: { EditKeyvisual, DomainMarketSelection },

  props: {
    module: Object as () => Module,
    updates: Object as () => ModuleEditUpdates,
    masterLanguage: {
      type: String,
      required: true
    },
    masterLanguageTag: Object as () => Tag,
    translatableLanguages: [],
    languages: [],
    languageTags: Array as () => TagWithName[],
    userGroupTags: Array as () => TagWithName[]
  },

  data () {
    return {
      keyVisualBlob: null as Blob | null,
      keyVisualUrl: this.module.keyvisual?.url as string | null | undefined,
      keyVisualId: this.module.keyvisual?.id as string | number | null | undefined,
      newMasterLanguage: this.masterLanguage,
      showActivatesAtDatePicker: false,
      showExpiresAtDatePicker: false,
      showKeyvisualSettings: false
    }
  },

  computed: {
    moduleId (): string {
      return this.module.id
    },
    name: {
      get (): string {
        return getTextForLanguage(this.module, this.masterLanguage)
      },
      set (v: string) {
        this.$set(this.updates, 'name', v)
      }
    },
    moduleContentType (): PackageFormat {
      return this.getModuleFormat(this.module)!
    },
    forceConfirm: {
      get (): boolean {
        return this.module.properties.forceConfirm
      },
      set (v: boolean) {
        this.$set(this.module.properties, 'forceConfirm', v)
      }
    },
    duration: {
      get (): number | undefined {
        return (this.module.duration === null) ? undefined : this.module.duration!
      },
      set (v: number | undefined) {
        let duration = (v === 0 || v === undefined) ? null : v
        if (typeof duration === 'string') duration = parseInt(duration, 10)
        this.$set(this.module, 'duration', duration)
      }
    },
    durationLabel: {
      get (): string {
        if (this.module.duration === null) {
          return this.$t('module.edit.noDuration') as string
        }
        return (this.module.duration === 0) ? `< 1 ${this.$t('module.edit.minutes')}` : `${this.module.duration!} ${this.$t('module.edit.minutes')}`
      }
    },
    sourceInfo (): Record<string, any> {
      switch (this.module.type) {
        case 'package':
          if (this.module.packages.length > 0) {
            const masterPackage = this.module.packages.filter(pkg => pkg.is_master)[0]
            const name = masterPackage.properties?.original_package_name ? masterPackage.properties?.original_package_name : 'n/a'
            const size = masterPackage.properties?.original_package_size ? this.formatFileSize(masterPackage.properties?.original_package_size) : 'n/a'
            return {
              label: this.$t('validation.attributes.name'),
              value: name,
              format: this.$t('module.packageTypes.' + masterPackage.type),
              updatedAt: masterPackage.updated_at.toLocaleString(),
              href: `/content-preview/${this.module.id}`,
              size: size
            }
          }
          break
        case 'download':
        case 'pdf':
        case 'media':
          if (this.module.downloads.length > 0) {
            return {
              label: this.$t('validation.attributes.name'),
              value: this.module.downloads[0].masterFile!.filename,
              updatedAt: this.module.downloads[0].masterFile!.updated_at.toLocaleString(),
              href: `/content-preview/${this.module.id}`,
              size: this.formatFileSize(this.module.downloads[0].masterFile!.size)
            }
          }
          break
        case 'external':
          if (this.module.links.length > 0) {
            return {
              label: this.$t('core.global.url'),
              value: this.module.links.filter(link => link.is_master)[0].url!,
              updatedAt: this.module.links.filter(link => link.is_master)[0].updated_at.toLocaleString(),
              href: this.module.links.filter(link => link.is_master)[0].url!
            }
          }
          break
      }
      return {
        label: 'n/a',
        updatedAt: '',
        href: ''
      }
    },
    activatesAt: {
      get (): string | null {
        return (this.module.activates_at !== null) ? this.module.activates_at.substr(0, 10) : null
      },
      set (v: string | null) {
        this.$set(this.module, 'activates_at', v ? v + ' 00:00:00' : null)
      }
    },
    expiresAt: {
      get (): string | null {
        return (this.module.expires_at !== null) ? this.module.expires_at.substr(0, 10) : null
      },
      set (v: string | null) {
        this.$set(this.module, 'expires_at', v ? v + ' 00:00:00' : null)
      }
    },
    availableTrackingTypes (): object[] {
      const trackingTypes = [{
        text: this.$t('module.trackingTypes.personal'),
        value: 'personal'
      }]

      if (this.module.type !== 'simpl' && this.module.type !== 'test') {
        trackingTypes.push({
          text: this.$t('module.trackingTypes.none'),
          value: 'none'
        })
      }
      if (this.module.type !== 'test') {
        trackingTypes.push({
          text: this.$t('module.trackingTypes.anon'),
          value: 'anon'
        })
        trackingTypes.push({
          text: this.$t('module.trackingTypes.anonAuthed'),
          value: 'anon_authed'
        })
      }
      return trackingTypes
    },
    availableStatus (): object[] {
      return [{
        text: this.$t('module.statuss.working'),
        value: 'working'
      }, {
        text: this.$t('module.statuss.beta'),
        value: 'beta'
      }, {
        text: this.$t('module.statuss.rc'),
        value: 'rc'
      }, {
        text: this.$t('module.statuss.approved'),
        value: 'approved'
      }, {
        text: this.$t('module.statuss.released'),
        value: 'released'
      }]
    },
    languagesSorted (): string[] {
      return (this.languages as []).sort()
    },
    showDatePickerDialog: {
      get (): boolean {
        return this.showActivatesAtDatePicker || this.showExpiresAtDatePicker
      },
      set () {
        this.showActivatesAtDatePicker = false
        this.showExpiresAtDatePicker = false
      }
    }
  },

  watch: {
    newMasterLanguage (v, o) {
      if (v !== o) {
        this.updateMaster(this.masterLanguage, v)
      }
    },
    'module.tracking_type' (v) {
      if (v === 'none') {
        this.forceConfirm = false
      }
    }
  },

  methods: {
    getTextForUserLanguage,
    formatFileSize,

    uploadKeyVisual ({ blob }: { blob: Blob }): void {
      (blob as any).name = `keyvisual-${this.module.id}`

      this.keyVisualUrl = URL.createObjectURL(blob)
      this.keyVisualId = null
      this.keyVisualBlob = blob
      this.$set(this.module, 'keyVisualFile', new File([blob], `keyvisual-${this.module.id}`, { type: blob.type }))
      this.$set(this.module, 'keyVisualId', this.keyVisualId)
    },

    keyVisualChanged (keyvisual: GraphQLFile): void {
      this.keyVisualUrl = keyvisual.url
      this.keyVisualId = keyvisual.id as string
      this.$set(this.module, 'keyVisualFile', null)
      this.$set(this.module, 'keyVisualId', this.keyVisualId)
    },

    async removeKeyVisual () {
      if (await deleteConfirm(this, 'Keyvisual')) {
        this.keyVisualUrl = null
        this.keyVisualBlob = null
        this.keyVisualId = null
        this.$set(this.module, 'keyVisualFile', null)
        this.$set(this.module, 'keyVisualId', null)
      }
    },

    updateMaster (old: any, newMaster: any) {
      // TODO: Add logic for new language column here
      if (!this.updates.lang[newMaster]) {
        this.$set(this.updates.lang, newMaster, {})
      }

      if (!this.updates.lang[old]) {
        this.$set(this.updates.lang, old, {})
      }

      if (this.module.type === 'package') {
        this.updateMasterPackage(old, newMaster)
      } else if (this.module.type === 'external') {
        this.updateMasterLink(old, newMaster)
      } else if (this.module.type !== 'simpl') {
        this.updateMasterFile(old, newMaster)
      }
    },

    updateMasterLink (old: any, newMaster: any): void {
      this.$set(this.updates.lang[newMaster], 'is_master', true)
      this.$set(this.updates.lang[newMaster], 'link',
                this.module.links!.filter(link => link!.languagecode === newMaster)![0]!.url)

      this.$set(this.updates.lang[old], 'is_master', false)
      this.$set(this.updates.lang[old], 'link',
                this.module.links!.filter(link => link!.languagecode === old)![0]!.url)
    },

    updateMasterPackage (old: any, newMaster: any): void {
      if (!this.updates.lang[newMaster]) {
        this.$set(this.updates.lang, newMaster, {})
      }

      if (!this.updates.lang[old]) {
        this.$set(this.updates.lang, old, {})
      }

      this.$set(this.updates.lang[newMaster], 'is_master', true)
      this.$set(this.updates.lang[newMaster], 'package',
                this.module.packages!.filter(pack => pack!.languagecode === newMaster)![0])

      this.$set(this.updates.lang[old], 'is_master', false)
      this.$set(this.updates.lang[old], 'package',
                this.module.packages!.filter(pack => pack!.languagecode === old)![0])
    },

    updateMasterFile (old: any, newMaster: any): void {
      const module = this.module.downloads![0]!.files!.filter(file => file!.languagecode === newMaster)![0]
      const masterID = module && module.id ? module.id : this.module.downloads![0]!.masterFile!.id
      this.$set(this.updates.lang, 'master', {
        masterFileID: masterID
      })
    }
  }
})
