































































import Vue from 'vue'

export default Vue.extend({
  name: 'ModuleEditSidebar',

  model: {},

  props: {
    value: Boolean,
    id: [Number, String],
    views: Array,
    featureMode: String
  },

  data: () => ({
    mini: false
  }),

  computed: {
    show: {
      get (): boolean {
        return this.value
      },
      set (v: boolean) {
        this.$emit('input', v)
      }
    },
    runId (): string {
      return this.$router.currentRoute.params.runId
    }
  },

  created () {
    this.mini = this.$vuetify.breakpoint.xs

    if (!this.show && !this.mini) this.show = true
  }
})
