import { render, staticRenderFns } from "./SettingsRankingStyle.vue?vue&type=template&id=1fd10e75&"
import script from "./SettingsRankingStyle.vue?vue&type=script&lang=ts&"
export * from "./SettingsRankingStyle.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
installComponents(component, {VRadio,VRadioGroup})
