
























































































import RunEvaluationChart from './RunEvaluationChart.vue'
import WordCloud from './WordCloud.vue'
import Ranking from './Ranking.vue'
import { defaultRankingProperties } from './config/defaultProperties'
import { TrackingVisualization } from 'packages/core/types/graphql'

export default {
  name: 'TrackingVisualizationItem',

  components: {
    WordCloud,
    RunEvaluationChart,
    Ranking
  },

  props: {
    trackingVisualization: Object as () => TrackingVisualization,
    runIdentifier: String,
    properties: Object,
    subheadline: String,
    useTestData: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      defaultRankingProperties: defaultRankingProperties,
      loading: 0,
      width: 0,
      height: 0,
      higherThanWide: false,
      rectWidth: 0,
      rectHeight: 0,
      offsetTop: 0
    }
  },

  computed: {
    headline (): string {
      return this.properties?.title
    },
    isWordCloud (): boolean {
      return this.properties?.diagramTypes.includes('wordcloud')
    },
    isRanking (): boolean {
      return this.trackingVisualization.type === 'ranking'
    }
  },

  updated () {
    this.calculateDimensions()
  },

  methods: {
    calculateDimensions () {
      if (this.$refs.chartWrapper) {
        const dummyElement = this.$refs.dimensionDummy as HTMLElement
        const rect = dummyElement.getBoundingClientRect()
        this.rectWidth = rect.width
        this.rectHeight = rect.height

        this.offsetTop = dummyElement.offsetTop

        const aspectRatio = 1.2
        const contentWrapper = this.$refs.contentWrapper as HTMLElement
        const contentWrapperHeight = contentWrapper.offsetHeight

        const wrapper = this.$refs.chartWrapper as HTMLElement
        const wrapperRatio = wrapper.offsetWidth / wrapper.offsetHeight

        if (wrapperRatio > aspectRatio) {
          this.higherThanWide = false
          this.width = wrapper.offsetHeight * aspectRatio
          this.height = wrapper.offsetHeight
        } else {
          this.higherThanWide = true
          this.width = wrapper.offsetWidth
          const calculatedHeight = wrapper.offsetWidth / aspectRatio
          const minHeight = contentWrapperHeight * 0.75
          this.height = calculatedHeight < minHeight ? minHeight : calculatedHeight
        }
      }
    }
  }
}
