










































































import mixins from 'vue-typed-mixins'
import Session from '../../mixins/Session'
import { GET_CONTENT_TREE, VIEW_CONTENT_TREE } from '@simpl/cms/graphql'
import { Query } from '@simpl/core/types/graphql'
import { ContentTree, IAdapterCMS } from '@simpl/cms/types'
import { getTextForUserLanguage, iOS } from '@simpl/core/utils'
import CMSRenderer from '@simpl/cms/components/CMSRenderer.vue'
import { getTreeContent } from '@simpl/cms/utils/tree-operations'
import normalizeTree from '@simpl/cms/utils/normalize-tree'
import { MUTATIONS } from '@simpl/cms/store/consts'
import StoreAdapter from '@simpl/cms-components/adapters/StoreAdapter'

export default mixins(Session).extend({
  name: 'ModuleCMSViewer',

  components: { CMSRenderer },

  data () {
    return {
      adapter: null! as IAdapterCMS,
      content: null! as ContentTree,
      selectedSiteIdIndex: null! as number,
      visitedSites: [] as number[],
      loading: 0,
      noContent: false,
      ios: iOS
    }
  },

  computed: {
    treeViewItems (): ContentTree[] {
      if (!this.content?.children?.length) return []

      const traverse = (item: ContentTree): any => {
        if (!(['chapter', 'site'].includes(item.type))) return false

        return {
          id: item.id,
          type: item.type,
          children: [...item.children.map(traverse)].filter(Boolean),
          data: item.data,
          texts: item.texts,
          name: getTextForUserLanguage(item)
        }
      }

      return this.content.children.map(traverse)
    },
    siteIDs (): any[] {
      return this.treeViewItems.reduce((sites: any[], currentItem: ContentTree) => {
        if (currentItem.children && currentItem.children.length) {
          currentItem.children.forEach((child: ContentTree) => {
            if (child.type === 'site') sites.push(child.id)
          })
        }
        return sites
      }, [])
    },
    selectedSiteId (): number | void {
      return this.siteIDs[this.selectedSiteIdIndex]
    },
    selectedContent (): ContentTree | undefined | null {
      return !this.content || !this.selectedSiteId ? null : getTreeContent(this.content, this.selectedSiteId)
    },
    allActiveComponentsCompleted (): boolean {
      return this.previewMode || this.$store.getters['cms/activeComponentsCompleted']
    },
    allActiveComponentsPoints (): number {
      return this.$store.getters['cms/activeComponentsPoints']
    },
    completed (): boolean {
      return this.selectedSiteIdIndex + 1 === this.siteIDs.length && this.allActiveComponentsCompleted
    }
  },

  watch: {
    selectedSiteIdIndex: {
      handler (v, o) {
        if (this.selectedSiteId) this.scrollToTop()

        if (v === undefined || this.noContent || this.previewMode) return

        if (!o || v < o) {
          this.adapter.setCurrentObjective(this.selectedSiteId!)
          this.storeSessionTracking({
            tracking_status: {
              bookmark: this.selectedSiteId ? `${this.selectedSiteId}` : ''
            }
          })
          return
        }

        const progress = this.updateProgress(this.selectedSiteId!)

        this.adapter.setCurrentObjective(this.selectedSiteId!)
        this.storeSessionTracking({
          tracking_status: {
            bookmark: this.selectedSiteId ? `${this.selectedSiteId}` : '',
            status: 'working',
            progress: progress,
            data: JSON.stringify({ visitedSites: this.visitedSites })
          }
        })
      }
    },
    content (v, o) {
      if (o) return
      if (this.previewMode) {
        this.selectedSiteIdIndex = 0
        return
      }

      this.sessionVisitedSites.forEach((siteId: number) => {
        if (siteId && this.visitedSites.indexOf(siteId) < 0) {
          this.visitedSites.push(siteId)
        }
      })

      this.selectedSiteIdIndex = this.bookmark
        ? this.siteIDs.indexOf(parseInt(this.bookmark))
        : 0
    },
    completed: {
      immediate: true,
      handler (v) {
        if (!v) return

        this.markSessionCompleted()
      }
    },
    siteIDs: {
      immediate: true,
      handler (v) {
        if (!v.length && !!this.content) this.notifyNoContent()
      }
    },
    allActiveComponentsCompleted (v) {
      if (!v || this.previewMode) return

      this.adapter.setCurrentObjective(this.selectedSiteId!)
      this.storeSessionTracking({
        tracking_status: {
          score: this.trackingStatus.score
            ? this.trackingStatus.score + this.allActiveComponentsPoints
            : this.allActiveComponentsPoints
        }
      })
    }
  },

  apollo: {
    content: {
      query () {
        return this.previewMode ? GET_CONTENT_TREE : VIEW_CONTENT_TREE
      },

      skip () {
        return !this.session && !this.previewMode
      },

      variables () {
        return {
          moduleId: this.module.id
        }
      },

      update (result: Query): ContentTree | null {
        const contentTree = this.previewMode ? result.contentTree : result.viewContentTree

        if (!contentTree) {
          this.notifyNoContent()
          return null
        }

        return normalizeTree(contentTree)
      },

      error (error: Error): void {
        console.error(error)
      },
      context () {
        return this.previewMode
          ? {}
          : {
            headers: {
              simplsession: this.session.token
            }
          }
      },

      loadingKey: 'loading'
    }
  },

  methods: {
    updateProgress (currentSiteId: number): number {
      if (currentSiteId && !this.visitedSites.includes(currentSiteId)) {
        this.visitedSites.push(currentSiteId)
      }
      return Math.round((this.visitedSites.length / this.siteIDs.length) * 100)
    },
    backToDashboard () {
      if (this.run) {
        this.$router.push(`/${this.run.type}/${this.run.identifier}`)
      } else {
        window.close()
      }
    },
    notifyNoContent () {
      this.noContent = true
      this.$notification.publish('bottom', {
        message: this.$t('module.cmsViewer.noContent'),
        type: 'error',
        color: 'error'
      })
    },
    scrollToTop () {
      const rendererEl = this.$el.ownerDocument!.querySelector('.cms-renderer-container')!
      const scrollTop = rendererEl.scrollTop
      rendererEl.scrollTop = 0
    }
  },

  async created () {
    if (!this.previewMode) {
      this.adapter = new StoreAdapter()
      await this.adapter.init(this.run.id, this.module.id)
    }
    this.$store.commit(`cms/${MUTATIONS.SET_EDIT_MODE}`, false)
  },

  beforeMount () {
    this.$store.commit(`cms/${MUTATIONS.UNREGISTER_ALL_ACTIVE_COMPONENT_IDS}`)
  }
})
