















import Vue from 'vue'

export default Vue.extend({
  name: 'SettingsDataSource',

  props: {
    dataSources: Array as () => string[],
    dataSource: String
  },

  computed: {
    internalDataSource: {
      get (): number {
        return this.dataSources.indexOf(this.dataSource)
      },
      set (v: number) {
        this.$emit('input', { dataSource: this.dataSources[v] })
      }
    }
  }
})
