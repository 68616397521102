























































































import Vue from 'vue'
import TrackingVisualizationItemComponent from './TrackingVisualizationItem.vue'
import { RunEvaluation, TrackingVisualization } from '@simpl/core/types/graphql'
import { getTextForTemporaryUserLanguage } from '@simpl/core/utils'
import TrackingVisualizationSidebar from '../components/TrackingVisualizationSidebar.vue'
import { defaultProperties, defaultRankingProperties } from './config/defaultProperties'
import parseTrackingVisualizationItem from '../parsers/TrackingVisualizationItemParser'
import getFirstItem from '../utils/get-first-item'
import htmlDecode from '../utils/html-decode'
import { sortByOrderedArray } from '../utils/sort'

export default Vue.extend({
  name: 'TrackingVisualizationWizard',

  components: {
    TrackingVisualizationItemComponent,
    TrackingVisualizationSidebar
  },

  props: {
    runIdentifier: String,
    evaluation: Object as () => RunEvaluation
  },

  data: function () {
    return {
      defaultProperties: defaultProperties,
      defaultRankingProperties: defaultRankingProperties,
      panel: 0,
      loading: 0,
      showSidebar: false
    }
  },

  computed: {
    eventAndModuleName (): string {
      const runName = getTextForTemporaryUserLanguage(this.evaluation.run)
      const evalName = getTextForTemporaryUserLanguage(this.evaluation)

      if (evalName) {
        return `${runName} | ${evalName}`
      }

      const firstItem = this.orderedVisualizations[this.panel]?.items[0]

      if (!firstItem) {
        return `${runName}`
      }

      const moduleName = getTextForTemporaryUserLanguage(firstItem)
      return `${runName} | ${moduleName}`
    },

    visualizationForPanel (): TrackingVisualization[] {
      return this.orderedVisualizations.filter((item, index) => this.panel === index)
    },

    orderedVisualizations (): TrackingVisualization[] {
      if (!this.evaluation?.properties?.visualizationOrder) {
        return this.evaluation?.trackingVisualizations || []
      }

      const visualizationOrder = this.evaluation.properties.visualizationOrder
      const reversedVisualizationOrder = [...visualizationOrder].reverse()
      const visualizations = this.evaluation!.trackingVisualizations.sort((a, b) =>
        sortByOrderedArray(a, b, 'id', reversedVisualizationOrder)
      )
      return visualizations
    },

    orderedSidebarTabs (): Record<string, any>[] {
      if (!this.orderedVisualizations) {
        return []
      }
      return this.orderedVisualizations.map((visualization: TrackingVisualization) => {
        const firstItem = getFirstItem(visualization)
        const text = getTextForTemporaryUserLanguage(firstItem?.content_headline, 'text')
        const sanitizedText = htmlDecode(text)

        return {
          id: visualization.id,
          text: sanitizedText
        }
      })
    },
    rankingProperties (): Record<string, any> {
      return {
        ...this.defaultRankingProperties,
        ...this.trackingVisualizationProperties
      }
    },
    parsedProperties (): Record<string, any> {
      if (this.orderedVisualizations[this.panel]) {
        const visualization = this.orderedVisualizations[this.panel]
        if (visualization.type === 'ranking') {
          return this.rankingProperties
        }

        const firstItem = getFirstItem(visualization)
        if (firstItem) {
          const parsed = parseTrackingVisualizationItem(firstItem)
          return {
            ...this.defaultProperties,
            ...parsed,
            ...this.trackingVisualizationProperties
          }
        }
      }
      return this.defaultProperties
    },
    trackingVisualizationProperties (): Record<string, any> {
      if (this.orderedVisualizations[this.panel]) {
        const visualization = this.orderedVisualizations[this.panel]
        if (!visualization) return {}
        return visualization.properties
      }
      return {}
    }
  },

  methods: {
    back () {
      this.panel--
    },
    next () {
      this.panel++
    },

    changeItem (item:Record<string, any>) {
      this.panel = this.orderedVisualizations.findIndex((ov:Record<string, any>) => ov.id === item.id)
      this.showSidebar = false
    }
  }
})
