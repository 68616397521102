

































import mixins from 'vue-typed-mixins'
import Session from '../../mixins/Session'
import PDFViewer from '@simpl/core/components/pdf/PDFViewer.vue'
import debounce from 'lodash.debounce'
import { getTextForUserLanguage } from '@simpl/core/utils/text'
import { File, Tag } from '@simpl/core/types/graphql'
import store from '@simpl/core/plugins/store'

export default mixins(Session).extend({
  name: 'ModulePDFViewer',

  components: {
    PDFViewer
  },

  props: {
    showConfirmHint: Boolean
  },

  data: () => ({
    page: 0,
    progress: 0,
    startPage: 1,
    confirmed: false
  }),

  computed: {
    langFile (): File {
      const moduleFile = this.module.downloads![0]!
      const userLanguage = this.$store.state.auth.temporaryLanguage || this.$store.state.auth.user.languagecode
      const marketTags = store.state.auth?.user?.tags?.filter((tag: Tag) => tag.category!.identifier === 'market')
      const marketLanguage = store.state.auth?.domain?.markets && marketTags.length
        ? `${userLanguage}-${marketTags[0].identifier}`
        : userLanguage

      const langFile = (moduleFile.files || []).find(f => f!.languagecode === marketLanguage) || (moduleFile.files || []).find(f => f!.languagecode === userLanguage)
      return langFile || moduleFile.masterFile!
    },

    moduleTitle (): string {
      return getTextForUserLanguage(this.module)
    },
    canConfirm (): boolean {
      return this.trackingStatus?.progress! === 100
    },
    forceConfirm (): boolean {
      return !this.previewMode && this.module.properties?.forceConfirm
    }
  },

  watch: {
    progress: 'updateTracking',
    page: 'updateTracking',
    confirmed (v) {
      if (v && this.module.properties?.forceConfirm && this.trackingStatus?.progress === 100) {
        const data = {
          tracking_status: {
            bookmark: this.trackingStatus.bookmark,
            data: JSON.stringify(this.trackingStatus.data),
            objectives: this.trackingStatus.objectives,
            progress: this.trackingStatus.progress,
            score: 500,
            status: this.trackingStatus.status
          }
        }
        data.tracking_status.status = 'completed'
        this.storeSessionTracking(data)
        this.$emit('update:showConfirmHint', false)
      }
    }
  },

  methods: {
    onProgress (progress: number): void {
      this.progress = progress
    },

    onPage (page: number): void {
      this.page = page
    },

    afterSessionCreated (): void {
      this.startPage = parseInt(this.trackingStatus?.data?.page) || 1
      this.confirmed = this.trackingStatus?.status === 'completed'
    },

    updateTracking: debounce(function (this: any): void {
      const data = {
        tracking_status: {
          data: JSON.stringify({ page: this.page })
        }
      } as any

      if (this.trackingStatus?.status !== 'completed') {
        const progress = Math.round(this.progress * 100)
        data.tracking_status.progress = progress
        data.tracking_status.score = 0
        data.tracking_status.status = 'working'

        if (progress === 100 && !this.module.properties?.forceConfirm) {
          data.tracking_status.score = 500
          data.tracking_status.status = 'completed'
        }
      }

      this.storeSessionTracking(data)
    }, 100)
  },

  mounted () {
    if (this.forceConfirm && !this.confirmed) {
      this.$emit('update:showConfirmHint', true)
    }
  }
}
)
