









import Vue from 'vue'
import StructureGroup from '../settings/structure-view/StructureGroup.vue'

export default Vue.extend({
  name: 'SettingsCategories',

  components: {
    StructureGroup
  },

  props: {
    selected: Number,
    children: Array as () => number[] | string[]
  },

  computed: {
    internalChildren: {
      get (): string[] {
        const childrenAsStrings = this.children.map((child: number | string) => String(child))
        return childrenAsStrings
      }
    },
    internalSelected: {
      get (): number {
        return this.selected
      }
    }
  },

  methods: {
    update (v: number) {
      this.$emit('input', v)
    }
  }
})
