



































































































import Vue from 'vue'
import TrackingVisualizationItemComponent from './TrackingVisualizationItem.vue'
import SettingsColors from '../components/settings/SettingsColors.vue'
import SettingsTitle from '../components/settings/SettingsTitle.vue'
import SettingsRankingStyle from '../components/settings/SettingsRankingStyle.vue'
import SettingsUserProperties from '../components/settings/SettingsUserProperties.vue'
import SettingsRankProperties from '../components/settings/SettingsRankProperties.vue'
import SettingsDataSource from '../components/settings/SettingsDataSource.vue'
import { Query, Run, TrackingVisualization } from '@simpl/core/types/graphql'
import { defaultRankingProperties } from './config/defaultProperties'
import { TRACKING_VISUALIZATION, UPDATE_TRACKING_VISUALIZATION } from '../graphql'
import { getTextForTemporaryUserLanguage } from '@simpl/core/utils'
import { GET_RUN_TEXTS_ONLY } from '@simpl/base-management/runs/graphql'

export default Vue.extend({
  name: 'RankingEdit',

  components: {
    TrackingVisualizationItemComponent,
    SettingsColors,
    SettingsTitle,
    SettingsRankingStyle,
    SettingsUserProperties,
    SettingsRankProperties,
    SettingsDataSource
  },

  props: {
    visualizationId: [String, Number],
    runIdentifier: [String, Number]
  },

  breadcrumbs () {
    const featureMode = (this.$route.path.substr(1, 6) === 'course') ? 'course' : 'run'
    const breadcrumbs: any[] = [{
      text: 'core.action.edit',
      to: null
    }]
    breadcrumbs.unshift({
      text: 'trackingVisualization.global.visualizations',
      to: `/${featureMode}/${this.runIdentifier}/evaluation/${this.evaluationId}/visualizations`
    })
    if (this.runIdentifier && getTextForTemporaryUserLanguage(this.run) !== 'display_name') {
      breadcrumbs.unshift({
        title: getTextForTemporaryUserLanguage(this.run),
        to: `/${featureMode}/${this.runIdentifier}/evaluations`
      })
    }
    breadcrumbs.unshift({
      text: `run.global.${featureMode}s`,
      to: `/${featureMode}s`
    })
    return breadcrumbs
  },

  data: function () {
    return {
      run: null! as Run,
      trackingVisualization: null! as TrackingVisualization,
      loading: false,
      defaultRankingProperties: defaultRankingProperties,
      editOnlyProperties: {} as Record<string, any>,
      customProperties: {} as Record<string, any>,
      panel: -1,
      settingsColWidth: '360'
    }
  },

  computed: {
    expansionPanels ():Record<string, any>[] {
      return [
        {
          header: this.$t('trackingVisualization.settings.title'),
          key: 'title',
          component: SettingsTitle,
          componentProperties: {
            title: this.currentProperties.title
          },
          updateFunction: this.update
        }, {
          header: this.$t('trackingVisualization.settings.rankingStyle'),
          key: 'rankingStyle',
          component: SettingsRankingStyle,
          componentProperties: {
            rankingStyles: this.currentProperties.rankingStyles,
            rankingStyle: this.currentProperties.rankingStyle
          },
          updateFunction: this.update
        }, {
          header: this.$t('trackingVisualization.settings.userInformation'),
          key: 'highlightInfo',
          component: SettingsUserProperties,
          componentProperties: {
            all: this.currentProperties.userProperties,
            selected: this.currentProperties.selectedUserProperties
          },
          updateFunction: this.handleUpdateSelectedUserProperties
        }, {
          header: this.$t('trackingVisualization.settings.rankProperties'),
          key: 'rankProperties',
          component: SettingsRankProperties,
          componentProperties: {
            rankProperties: this.currentProperties.rankProperties,
            rankProperty: this.currentProperties.rankProperty
          },
          updateFunction: this.update
        }, {
          header: this.$t('trackingVisualization.settings.dataSource'),
          key: 'dataSource',
          component: SettingsDataSource,
          componentProperties: {
            dataSources: this.currentProperties.dataSources,
            dataSource: this.currentProperties.dataSource
          },
          updateFunction: this.updateEditOnly
        }
      ]
    },

    currentProperties (): Record<string, any> {
      return {
        ...this.defaultRankingProperties,
        ...this.trackingVisualizationProperties,
        ...this.customProperties
      }
    },
    trackingVisualizationProperties (): Record<string, any> {
      if (!this.trackingVisualization) return {}
      return this.trackingVisualization.properties
    },
    evaluationId (): null | string {
      return this.$router.currentRoute.params.evaluationIdentifier
    },
    pristine (): Boolean {
      return (Object.keys(this.customProperties).length === 0 && this.customProperties.constructor === Object)
    }
  },

  methods: {
    handleUpdateSelectedUserProperties (selected: Record<string, any>) {
      this.update({ selectedUserProperties: selected })
    },
    handleUpdateCategories (data: Record<string, any>) {
      if (data.type === 'updateTickAmount') {
        this.update({
          tickAmountData: { ...this.currentProperties.tickAmountData, current: data.value }
        })
      } else if (data.type === 'updateText') {
        const tsCategories = [...this.currentProperties.categories]
        tsCategories[data.index] = data.text

        this.update({
          labels: tsCategories,
          categories: tsCategories
        })
      }
    },

    update (data: Record<string, any>) {
      this.customProperties = {
        ...this.customProperties,
        ...data
      }
    },

    updateEditOnly (data: Record<string, any>) {
      this.editOnlyProperties = {
        ...this.editOnlyProperties,
        ...data
      }
    },

    reset () {
      this.customProperties = {}
      this.editOnlyProperties = {}
      this.mutate('successfullyReset')
    },

    async save () {
      this.mutate('successfullyEdited')
    },

    async mutate (notificationParameter: string) {
      this.loading = true
      const newProperties = {
        ...this.trackingVisualizationProperties, // previous saved TrackingVisualizationProperties
        ...this.customProperties // changed Properties
      }
      await this.$apollo.mutate({
        mutation: UPDATE_TRACKING_VISUALIZATION,
        variables: {
          data: {
            id: this.trackingVisualization?.id,
            properties: JSON.stringify(newProperties)
          }
        }
      })

      this.$notification.publish('bottom', {
        message: this.$t('trackingVisualization.notification.' + notificationParameter),
        type: 'success',
        color: 'success'
      })

      this.loading = false
      this.$emit('saved')

      await this.$apollo.queries.trackingVisualization.refetch()
    }
  },

  apollo: {
    run: {
      query: GET_RUN_TEXTS_ONLY,

      skip (): boolean {
        return !this.runIdentifier
      },

      variables (): object {
        return {
          id: this.runIdentifier
        }
      },

      update (data: Query): Run {
        return data.run!
      }
    },
    trackingVisualization: {
      query: TRACKING_VISUALIZATION,

      loadingKey: 'loadingTrackingVisualization',

      variables (): Record<string, any> {
        return {
          id: this.visualizationId
        }
      },

      update (result: Query): TrackingVisualization | null {
        this.customProperties = {}
        if (!result?.trackingVisualization) return null
        return result.trackingVisualization
      }
    }
  }
})
