



















import VideoPlayer from '@simpl/core/components/videoplayer/VideoPlayer.vue'
import Session from '../../mixins/Session'

import mixins from 'vue-typed-mixins'
import throttle from 'lodash.throttle'

import { File, Module, Run, Tag } from '@simpl/core/types/graphql'
import store from '@simpl/core/plugins/store'

export default mixins(Session).extend({
  name: 'ModuleVideoViewer',

  components: { VideoPlayer },

  props: {
    run: Object as () => Run,
    module: Object as () => Module
  },

  data: () => ({
    volume: 1,
    progress: 0
  }),

  computed: {
    langFile (): File {
      const moduleFile = this.module.downloads![0]!
      const userLanguage = this.$store.state.auth.temporaryLanguage || this.$store.state.auth.user.languagecode
      const marketTags = store.state.auth?.user?.tags?.filter((tag: Tag) => tag.category!.identifier === 'market')
      const marketLanguage = store.state.auth?.domain?.markets && marketTags.length
        ? `${userLanguage}-${marketTags[0].identifier}`
        : userLanguage

      const langFile = (moduleFile.files || []).find(f => f!.languagecode === marketLanguage) || (moduleFile.files || []).find(f => f!.languagecode === userLanguage)

      return langFile || moduleFile.masterFile!
    }
  },

  watch: {
    progress: 'updateTracking',
    volume: 'updateTracking'
  },

  methods: {
    onProgress (v: number): void {
      this.progress = v
    },
    onVolumeChange (v: number): void {
      this.volume = v
    },
    updateTracking: throttle(async function (this: any) {
      const data = {
        tracking_status: {
          bookmark: this.progress === 1 ? '0' : this.progress.toString(),
          data: JSON.stringify({ volume: this.volume })
        }
      } as any

      if (this.trackingStatus.status !== 'completed') {
        data.tracking_status.progress = Math.round(this.progress * 100)
        data.tracking_status.score = Math.round(this.progress * 100) > 90 ? 500 : 0
        data.tracking_status.status = Math.round(this.progress * 100) > 90 ? 'completed' : 'working'
      }

      await this.storeSessionTracking(data)
    }, 5000)
  },

  async beforeDestroy () {
    await this.updateTracking()
  }
}
)
