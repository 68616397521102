



















import Vue from 'vue'

export default Vue.extend({
  name: 'SettingsTitle',

  props: {
    xAxisTitle: String,
    yAxisTitle: String
  },

  data () {
    return {
      timer: null as any
    }
  },

  computed: {
    internalXAxisTitle: {
      get (): string {
        return this.xAxisTitle
      },
      set (v: string) {
        clearTimeout(this.timer)
        this.timer = setTimeout(() => this.$emit('input', { xAxisTitle: v }), 50)
      }
    },
    internalYAxisTitle: {
      get (): string {
        return this.yAxisTitle
      },
      set (v: string) {
        clearTimeout(this.timer)
        this.timer = setTimeout(() => this.$emit('input', { yAxisTitle: v }), 50)
      }
    }
  }
})
