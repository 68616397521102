















import Vue from 'vue'

export default Vue.extend({
  name: 'SettingsRankProperties',

  props: {
    rankProperties: Array,
    rankProperty: String
  },

  computed: {
    internalRankProperty: {
      get (): number {
        return this.rankProperties.indexOf(this.rankProperty)
      },
      set (v: number) {
        this.$emit('input', { rankProperty: this.rankProperties[v] })
      }
    }
  }
})
