

































import Vue from 'vue'

export default Vue.extend({
  name: 'SettingsColors',

  props: {
    palettes: Array,
    colors: Array,
    isDistributed: Boolean,
    distributable: Boolean
  },

  computed: {
    internalDistributed: {
      get (): boolean {
        return this.isDistributed
      },
      set (v: boolean) {
        this.$emit('input', { distributed: v })
      }
    },
    internalPalette: {
      get (): number {
        return this.palettes.findIndex(palette => JSON.stringify(palette) === JSON.stringify(this.colors))
      },
      set (v: number) {
        this.$emit('input', { colors: this.palettes[v] })
      }
    }
  }
})
