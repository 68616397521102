














import { LanguageKeyPropertyKeyMatchSelect } from 'packages/tracking-evaluation/types'
import Vue from 'vue'

export default Vue.extend({
  name: 'SettingsUserProperties',

  props: {
    all: {
      type: Array as () => LanguageKeyPropertyKeyMatchSelect[]
    },
    selected: {
      type: Array as () => string[]
    }
  },

  computed: {
    internalSelected: {
      get (): string[] {
        return this.selected
      },
      set (v: string[]) {
        this.$emit('input', v)
      }
    }
  }
})
