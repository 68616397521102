










import Vue from 'vue'
import { RunEvaluation } from '@simpl/core/types/graphql'
import { SESSION_TRACKING_STORED, TRACKINGS } from '@simpl/base-management/runs/graphql'

import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { PieChart } from 'echarts/charts'
import 'echarts-wordcloud'
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent
} from 'echarts/components'
import VChart, { THEME_KEY } from 'vue-echarts'

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent
])

Vue.component(VChart)

export default Vue.extend({
  name: 'WordCloud',

  components: {
    VChart
  },
  provide: {
    [THEME_KEY]: 'dark'
  },
  props: {
    trackingVisualization: Object,
    rotationType: String,
    width: Number,
    height: Number,
    colors: {
      type: Array,
      required: true
    },
    useTestData: Boolean
  },

  data () {
    return {
      rotationRange: [-0, 0],
      rotationStep: 0,
      trackedWords: [] as { name: string, value: number }[],
      smallestMinSize: 12,
      smallestMaxSize: 16,

      internMinSize: 100,
      internMaxSize: 520,

      hasUpdated: false,
      timer: null as any,
      resizeTimer: null as any
    }
  },

  computed: {
    words (): { name: string, value: number }[] {
      return this.useTestData ? this.testWords : this.trackedWords
    },
    minSize: {
      get (): number {
        return this.internMinSize
      },
      set (v: number) {
        this.internMinSize = v
      }
    },
    maxSize: {
      get (): number {
        return this.internMaxSize
      },
      set (v: number) {
        this.internMaxSize = v
      }
    },
    option (): any {
      return {
        backgroundColor: 'rgba(0, 0, 0, 0)',
        series: [{
          type: 'wordCloud',

          // The shape of the "cloud" to draw. Can be any polar equation represented as a
          // callback function, or a keyword present. Available presents are circle (default),
          // cardioid (apple or heart shape curve, the most known polar equation), diamond (
          // alias of square), triangle-forward, triangle, (alias of triangle-upright, pentagon, and star.

          // shape: 'circle',
          // shape: 'diamond',
          shape: 'heart',

          // A silhouette image which the white area will be excluded from drawing texts.
          // The shape option will continue to apply as the shape of the cloud to grow.

          // maskImage: maskImage,

          // Following left/top/width/height/right/bottom are used for positioning the word cloud
          // Default to be put in the center and has 75% x 80% size.

          // left: 'center',
          // top: 'center',
          width: '100%',
          height: '100%',
          right: null,
          bottom: null,

          // Text size range which the value in data will be mapped to.
          // Default to have minimum 12px and maximum 60px size.

          sizeRange: [this.minSize, this.maxSize],

          // Text rotation range and step in degree. Text will be rotated randomly in range [-90, 90] by rotationStep 45

          rotationRange: this.rotationRange,
          rotationStep: this.rotationStep,

          // size of the grid in pixels for marking the availability of the canvas
          // the larger the grid size, the bigger the gap between words.

          gridSize: 8,

          // set to true to allow word being draw partly outside of the canvas.
          // Allow word bigger than the size of the canvas to be drawn
          drawOutOfBound: false,
          shrinkToFit: true,

          // If perform layout animation.
          // NOTE disable it will lead to UI blocking when there is lots of words.
          layoutAnimation: true,

          // Global text style
          textStyle: {
            fontFamily: 'sans-serif',
            fontWeight: 'bold',
            color: this.setColor
          },
          emphasis: {
            focus: 'self',
            scale: true,
            scaleSize: 20,

            textStyle: {
              textShadowBlur: 10,
              textShadowColor: '#333'
            }
          },
          data: this.words
        }]
      }
    },
    runId (): null | string {
      return this.$router.currentRoute.params.runIdentifier
    },
    mappedParameter (): Record<string, any>[] {
      return this.trackingVisualization.items.map((item: Record<string, any>) => {
        return {
          run_id: item.run?.id ? item.run.id : this.runId,
          module_id: item.module?.id,
          objective: item.objective,
          site: item.site,
          key: item.key
        }
      })
    },
    setColor: function () {
      const colors = this.colors
      return (item) => {
        const colorIndex = item.dataIndex % colors.length
        return colors[colorIndex]
      }
    },
    minimumWords (): { name: string, value: number }[] {
      return [
        { name: 'LMS', value: 2 },
        { name: 'Exterior', value: 1 },
        { name: 'Motor', value: 1 },
        { name: 'Sales', value: 1 },
        { name: 'Experience', value: 1 },
        { name: 'Company', value: 1 }
      ]
    },
    testWords (): { name: string, value: number }[] {
      return [
        { name: 'Interior', value: 30 },
        { name: 'Exterior', value: 2 },
        { name: 'Motor', value: 12 },
        { name: 'Sales', value: 15 },
        { name: 'Experience', value: 35 },
        { name: 'Company', value: 1 },
        { name: 'Media', value: 12 },
        { name: 'Discover', value: 2 },
        { name: 'Assessment', value: 6 },
        { name: 'Future', value: 15 },
        { name: 'Entertainment', value: 40 },
        { name: 'Value', value: 5 },
        { name: 'Mobility', value: 30 },
        { name: 'Progress', value: 10 },
        { name: 'Country', value: 3 },
        { name: 'Trends', value: 22 },
        { name: 'Technology', value: 25 },
        { name: 'Relations', value: 2 },
        { name: 'Sustainability', value: 2 },
        { name: 'Inspiration', value: 7 },
        { name: 'Lifestyle', value: 15 },
        { name: 'Sport', value: 20 },
        { name: 'Investment', value: 1 },
        { name: 'Contact', value: 1 },
        { name: 'Model', value: 1 },
        { name: 'Branding', value: 30 },
        { name: 'Digital', value: 2 },
        { name: 'Landscape', value: 12 },
        { name: 'E-Mobility', value: 15 },
        { name: 'Look & Feel', value: 35 },
        { name: 'Project', value: 1 },
        { name: 'Champion', value: 12 },
        { name: 'Fossil-free', value: 2 },
        { name: 'Dimensions', value: 6 },
        { name: 'Enhancement', value: 15 },
        { name: 'Autonomous Driving', value: 40 },
        { name: 'Vehicle', value: 5 },
        { name: 'Dedication', value: 8 },
        { name: 'Premium', value: 10 },
        { name: 'Charging Solutions', value: 3 },
        { name: 'Intensivity', value: 22 },
        { name: 'Research', value: 25 },
        { name: 'Relations', value: 2 },
        { name: 'Risk', value: 2 },
        { name: 'Perfectionism', value: 15 },
        { name: 'Speed', value: 10 },
        { name: 'Benefits', value: 10 }
      ]
    }
  },

  watch: {
    rotationType (v) {
      this.setRotation(v)
      this.update()
    },
    apolloTrackings (v) {
      this.mapWordCloudTrackingData(v)
      this.update()
    },
    useTestData (v: boolean) {
      this.update()
    },
    width (v) {
      const echart = this.$refs.echart as typeof VChart
      if (echart) {
        clearTimeout(this.resizeTimer)
        this.resizeTimer = setTimeout(this.handleResize, 100)
      }
    }
  },

  mounted () {
    window.addEventListener('wordcloudtoosmall', this.redrawWordCloudSmaller)
    window.addEventListener('wordcloudoutofbound', this.redrawWordCloudSmaller)
    this.update()
  },

  beforeDestroy () {
    window.removeEventListener('wordcloudtoosmall', this.redrawWordCloudSmaller)
    window.removeEventListener('wordcloudoutofbound', this.redrawWordCloudSmaller)
  },

  methods: {
    update () {
      this.hasUpdated = false
      clearTimeout(this.timer)
      this.timer = setTimeout(() => { this.hasUpdated = true }, 150)
      this.findRange(this.words)
    },
    handleResize () {
      const echart = this.$refs.echart as typeof VChart
      echart.resize()
      this.update()
    },
    findRange (words: { name: string, value: number }[]) {
      if (!words.length) {
        return
      }

      let minValue = undefined as undefined | number
      let maxValue = undefined as undefined | number
      words.forEach(word => {
        if (!minValue || minValue > word.value) minValue = word.value
        if (!maxValue || maxValue < word.value) maxValue = word.value
      })

      this.maxSize = 520
      const proportionalMinSize = Math.floor(this.maxSize * minValue! / maxValue!)
      this.minSize = proportionalMinSize > this.smallestMinSize ? proportionalMinSize : this.smallestMinSize
    },
    redrawWordCloudSmaller (e:Event) {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => { this.hasUpdated = true }, 150)

      if (this.minSize * 0.8 >= this.smallestMinSize) {
        this.minSize = Math.floor(this.minSize * 0.8)
      }
      if (this.maxSize * 0.8 >= this.smallestMaxSize) {
        this.maxSize = Math.floor(this.maxSize * 0.8)
      } else {
        console.error('fontSize cannot get smaller')
      }
    },

    setRotation (type: string) {
      switch (type) {
        case 'simple':
          this.rotationRange = [-0, 0]
          this.rotationStep = 0
          break

        case 'medium':
          this.rotationRange = [-45, 90]
          this.rotationStep = 45
          break

        case 'complex':
          this.rotationRange = [-90, 90]
          this.rotationStep = 30
          break

        default:
          this.rotationRange = [-0, 0]
          this.rotationStep = 0
      }
    },
    mapWordCloudTrackingData (trackings: Record<string, any>) {
      const firstItem = this.trackingVisualization.items[0]
      if (firstItem) {
        const componentConstructor = Vue.component('CTextInput')
        this.trackedWords = componentConstructor.options.cms?.tracking?.parseTrackingForVisualization(trackings[0])
      }
    },
    async refetch () {
      await this.$apollo.queries.apolloTrackings.refetch()
    }
  },

  apollo: {
    apolloTrackings: {
      query: TRACKINGS,
      fetchPolicy: 'no-cache',

      skip (): boolean {
        return !this.trackingVisualization
      },

      variables (): Record<string, any> {
        const parameter = this.mappedParameter
        return {
          data: parameter
        }
      },

      update (result: RunEvaluation): Record<string, any>[] {
        return result.trackings
      },

      error (error: Error): void {
        console.error(error)
      },

      loadingKey: 'loading',

      subscribeToMore: {
        document: SESSION_TRACKING_STORED,
        variables (): Record<string, any> {
          const parameter = this.mappedParameter[0]

          return {
            run_id: parameter.run_id,
            module_id: parameter.module_id,
            key: parameter.key
          }
        },

        updateQuery (previous: RunEvaluation, { subscriptionData }: any) {
          const currentSubTracking = subscriptionData.data?.sessionTrackingStored?.trackings?.[0]
          const currentApolloTracking = this.apolloTrackings[0].tracking_data
          const currentTrackingData = currentApolloTracking.find(tracking => {
            return tracking.id === currentSubTracking.id
          })
          // TODO @cb: Ich kann die Items nicht einfach ersetzen, die Items sind nicht gleich, nur die Value müsste weitergegeben werden
          this.refetch()
        }
      }
    }
  }
})
