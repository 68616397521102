import BaseAdapter, { convertArraysToNumericObject } from './BaseAdapter'
import { SessionTrackingStatus, StoreSessionTrackingInput, User } from '@simpl/core/types/graphql'
import i18n from '@simpl/core/plugins/i18n'
import { hasOwn } from '../../../../core/utils'
import { trackingDataToCMI } from '../../tracking-to-cmi'

const STATUS_NOT_STARTED = 'not_started'
const STATUS_WORKING = 'working'
const STATUS_COMPLETED = 'completed'
const STATUS_PASSED = 'passed'
const STATUS_FAILED = 'failed'

export class Scorm12 implements BaseAdapter {
  toCMI (tracking: SessionTrackingStatus | undefined | null, user: User): Record<string, any> {
    // Fallback for old tracking data versions
    if (hasOwn(tracking?.data || {}, 'preferences')) {
      return trackingDataToCMI(tracking, user)
    }

    const trackingData = convertArraysToNumericObject(tracking?.data || {})

    trackingData.core = {
      lesson_location: tracking?.bookmark || null,
      ...(trackingData.core || {}),
      student_id: user.id,
      student_name: i18n.t('core.global.fullname', [user.firstname, user.lastname]) as string
    }

    return trackingData
  }

  toTracking (cmi: Record<any, any>): Omit<StoreSessionTrackingInput, 'token'> {
    return {
      tracking_status: {
        bookmark: cmi.core.lesson_location || '',
        status: scormStatusToTrackingStatus(cmi.core.lesson_status),
        objectives: JSON.stringify(cmi.objectives),
        score: +cmi.core.score.raw,
        progress: Math.round(calculateFloatStore(cmi.core.score)),
        data: JSON.stringify(cmi)
      }
    }
  }
}

function calculateFloatStore (score: { min?: string, max?: string, raw?: string }): number {
  const min = parseFloat(score.min || '0')
  const max = parseFloat(score.max || '100')
  const raw = parseFloat(score.raw || '0')

  return (raw - min) / (max - min) * 100
}

function scormStatusToTrackingStatus (status: string) {
  switch (status) {
    case 'not attempted':
      return STATUS_NOT_STARTED
    case 'completed':
      return STATUS_COMPLETED
    case 'passed':
      return STATUS_PASSED
    case 'failed':
      return STATUS_FAILED
    default:
      return STATUS_WORKING
  }
}

const adapterScorm12 = new Scorm12()

export default adapterScorm12
