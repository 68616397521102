































































































import mixins from 'vue-typed-mixins'
import ActiveDomainTags from '@simpl/core/mixins/apollo/ActiveDomainTags'
import FileUpload from '@simpl/core/mixins/apollo/FileUpload'
import ExportContent from '@simpl/core/mixins/utils/ImportExportContent'

import ModuleEditSidebar from '../components/ModuleEditSidebar.vue'

import ModuleEditBasic from './ModuleEditBasic.vue'
import ModuleEditVariants from './ModuleEditVariants.vue'
import ModuleEditTest from './ModuleEditTest.vue'

import { getTextForUserLanguage } from '@simpl/core/utils/text'
import { DELETE_CONTENT_TEXTS, GET_MODULE, MODULE_UPDATED, UPDATE_MODULE } from '../graphql'

import {
  Module,
  UpdateModuleInput,
  File as UploadedFile,
  ModulePackageVerification,
  CreateFilesRelation, Run, Query, Tag, RunDashboard, ModuleBasic
} from '@simpl/core/types/graphql'
import { hasOwn, objectHash } from '@simpl/core/utils/core'
import { GET_RUN_TEXTS_ONLY, RUN_UPDATED } from '../../runs/graphql'
import LanguageTags from '@simpl/core/mixins/apollo/LanguageTags'
import UserGroupTags from '@simpl/core/mixins/apollo/UserGroupTags'

type EditableModule = Module & {
  name: string
  description: string,
  keyVisualFile?: File | null,
  keyVisualId?: string | null,
  properties: Record<string, any>
  tagIds: string[]
}

export type ModuleEditUpdates = {
  lang: Record<string, ModuleEditUpdates>
  name: string,
  file: UploadedFile,
  oldFileId: string,
  package: ModulePackageVerification,
  [key: string]: any
}

export default mixins(LanguageTags, UserGroupTags, FileUpload, ActiveDomainTags, ExportContent).extend({
  name: 'ModuleEdit',

  components: {
    ModuleEditSidebar
  },

  props: {
    runId: [String, Number],
    id: [String, Number],
    view: {
      type: String,
      default: 'basic'
    }
  },

  breadcrumbs (): any[] {
    const breadcrumbs: any[] = [{
      text: 'core.action.edit',
      to: null
    }]

    if (this.module) {
      breadcrumbs.unshift({
        title: getTextForUserLanguage(this.module),
        to: null
      })
    }

    if (this.runId && !this.run) {
      breadcrumbs.unshift({
        text: 'run.edit.contents',
        to: `/${this.featureMode}/${this.runId}/basic`
      })
    }

    if (this.run) {
      breadcrumbs.unshift({
        title: getTextForUserLanguage(this.run) + ' / ' + this.$t('run.edit.contents'),
        to: `/${this.featureMode}/${this.runId}/contents`
      })
    }

    return breadcrumbs
  },

  data () {
    const featureMode = (this.$route.path.split('/')[1] === 'course') ? 'course' : 'run'
    return {
      sidebarMini: false,
      showSidebar: true,

      uploadContentCategory: 'keyvisual_module',
      keyVisualId: undefined as string | number | undefined | null,

      loading: 0,
      run: null! as Run,
      module: null! as EditableModule,
      updates: { lang: {} } as ModuleEditUpdates,

      initialHash: null! as string,
      currentHash: null! as string,

      deletableLanguageVariantTexts: [] as string[],

      featureMode
    }
  },

  computed: {
    views (): Record<string, any>[] {
      return [{
        key: 'basic',
        icon: 'mdi-record-circle-outline',
        component: ModuleEditBasic
      }, {
        key: 'variants',
        icon: 'mdi-flag-outline',
        component: ModuleEditVariants
      }, {
        key: 'test',
        icon: 'mdi-book-education',
        component: ModuleEditTest,
        hidden: this.module.type !== 'test'
      }]
    },
    masterLanguage (): string {
      if (this.module.type === 'external') {
        return this.module.links!.filter(pack => pack!.is_master)[0]!.languagecode ||
          this.module.languagecode ||
          process.env.VUE_APP_I18N_FALLBACK_LOCALE!
      } else if (this.module.type === 'package') {
        return this.module.packages!.filter(pack => pack!.is_master)[0]!.languagecode ||
          this.module.languagecode ||
          process.env.VUE_APP_I18N_FALLBACK_LOCALE!
      }

      return this.module.downloads![0]?.masterFile
        ? this.module.downloads![0]!.masterFile!.languagecode!
        : this.module.languagecode || process.env.VUE_APP_I18N_FALLBACK_LOCALE!
    },
    masterLanguageTag (): Tag | void {
      return this.languageTags.find(tag => tag.identifier === this.masterLanguage)
    },
    translatableLanguages (): any[] {
      let languages = [] as any[]
      // TODO: WIP: Add logic for new language column here
      if (this.module.type === 'package') {
        languages = this.module.properties.languageVariants || []
        languages = Array.from(new Set([...languages, ...this.module.packages!.map(pack => pack!.languagecode)]))
      } else if (this.module.type === 'external') {
        languages = Array.from(new Set([...languages, ...this.module.links!.map(pack => pack!.languagecode)]))
      } else if (this.module.type === 'simpl' || this.module.type === 'test') {
        languages = this.module.properties.languageVariants || []
        // languages = Array.from(new Set([...languages, ...this.module.tags!.filter(tag => tag!.category!.identifier === 'language').map(tag => tag!.identifier)]))
      } else if (this.module.type === 'generic') {
        //  TODO: set no other languages here?
      } else {
        if (this.module.downloads.length > 0) {
          languages = Array.from(new Set([...languages, ...this.module.downloads![0]!.files!.map(pack => pack!.languagecode).concat(this.masterLanguage)]))
        }
      }
      return languages
    },
    languages (): string[] {
      return this.translatableLanguages.filter((l: string) => l !== this.masterLanguage)
    },
    hasChanged (): boolean {
      const hasUpdates = Object.keys(this.updates).length > 1 || Object.keys(this.updates.lang).length > 0
      return hasUpdates || (this.initialHash !== this.currentHash)
    }
  },

  watch: {
    module: {
      deep: true,
      handler (v: any): void {
        this.currentHash = objectHash(v)
      }
    }
  },

  apollo: {
    run: {
      query: GET_RUN_TEXTS_ONLY,

      skip (): boolean {
        return !this.runId
      },

      variables (): object {
        return {
          id: this.runId
        }
      },

      update (data: Query): Run {
        return data.run!
      }
    },
    module: {
      query: GET_MODULE,

      loadingKey: 'loading',

      variables (): object {
        return { id: this.id }
      },

      update (data: Query): EditableModule {
        const module = data.module!

        if (!module) {
          this.$router.replace('/404')
          return null!
        }

        const retVal = {
          ...module,
          name: getTextForUserLanguage(module, 'display_name', false) || '',
          properties: { ...(module.properties || {}) },
          tagIds: module.tags!.map(t => `${t!.id}`)
        }

        this.initialHash = this.currentHash = objectHash(retVal)

        return retVal as any
      },

      subscribeToMore: {
        document: MODULE_UPDATED,

        fetchPolicy: 'no-cache',

        skip (): boolean {
          return !this.module
        },

        variables (): Record<string, any> {
          return {
            module_id: this.module!.id
          }
        },

        updateQuery (previous: ModuleBasic, { subscriptionData }: any) {
          this.$apollo.queries.module.refetch()
        }
      }
    }
  },

  methods: {
    getMutationVariables (): object {
      const variables: UpdateModuleInput = {
        id: String(this.id),
        active: this.module.active,
        languagecode: this.masterLanguage,
        sticky: this.module.sticky,
        status: this.module.status,
        duration: this.module.duration,
        tracking_type: this.module.tracking_type,
        texts: { create: [], delete: [] },
        downloads: { update: [] },
        links: { create: [], update: [], delete: [] },
        packages: { create: [], update: [], delete: [] },
        tags: { sync: this.module.tagIds }
      }

      if (this.module.activates_at !== null) {
        variables.activates_at = this.module.activates_at
      }
      if (this.module.expires_at !== null) {
        variables.expires_at = this.module.expires_at
      }

      if (this.keyVisualId) {
        variables.keyvisual_id = String(this.keyVisualId)
      } else {
        variables.keyvisual_id = null
      }

      variables.properties = JSON.stringify({
        ...this.module.properties,
        languageVariants: this.languages
      })

      this.getMutationsVariablesForLang(this.updates, variables)

      for (const lang in this.updates.lang) {
        this.getMutationsVariablesForLang(this.updates.lang[lang], variables, lang)
      }

      this.deleteLanguages(variables)

      return variables
    },

    getMutationsVariablesForLang (data: ModuleEditUpdates, variables: UpdateModuleInput, language?: string): void {
      if (data.name) {
        variables.texts!.create!.push({
          languagecode: language || this.masterLanguage,
          identifier: 'display_name',
          text: data.name
        })
      }
      if (data.description) {
        variables.texts!.create!.push({
          languagecode: language || this.masterLanguage,
          identifier: 'description',
          text: data.description
        })
      }

      if (data.oldFileId) {
        variables.downloads!.update!.push({
          id: this.module.downloads![0]!.id,
          type: 'module_content',
          files: {
            delete: [data.oldFileId]
          }
        })
      }

      if (data.masterFileID) {
        const fileData: CreateFilesRelation = {}

        if (language) {
          fileData.connect = [this.module.downloads![0]!.masterFile!.id!]
        }

        variables.downloads!.update!.push({
          id: this.module.downloads![0]!.id,
          type: 'module_content',
          master_file_id: data.masterFileID,
          files: fileData
        })
      }

      if (data.file) {
        const fileData: CreateFilesRelation = {}

        if (language) {
          fileData.connect = [data.file.id!]
        }

        variables.downloads!.update!.push({
          id: this.module.downloads![0]!.id,
          type: 'module_content',
          master_file_id: String(language ? this.module.downloads![0]!.masterFile!.id! : data.file.id!),
          files: fileData
        })
      }

      if (data.package) {
        const existing = this.module.packages!.find(p => language ? p!.languagecode === language : p!.is_master)

        if (existing) {
          variables.packages!.update!.push({
            id: existing.id,
            type: data.package.type,
            is_master: data.is_master || !language,
            path: data.package.path!,
            languagecode: language || this.masterLanguage,
            starter_file: data.package.starter_file,
            properties: (data.package as any).properties
          })
        } else {
          variables.packages!.create!.push({
            type: data.package.type,
            is_master: !language,
            path: data.package.path!,
            languagecode: language || this.masterLanguage,
            starter_file: data.package.starter_file,
            properties: (data.package as any).properties
          })
        }
      }

      if (data.link) {
        const existing = this.module.links!.find(p => language ? p!.languagecode === language : p!.is_master)

        if (existing) {
          variables.links!.update!.push({
            id: existing.id,
            type: 'external',
            is_master: data.is_master || !language,
            url: data.link!,
            languagecode: language || this.masterLanguage
          })
        } else {
          variables.links!.create!.push({
            type: 'external',
            is_master: !language,
            url: data.link!,
            languagecode: language || this.masterLanguage
          })
        }
      }
    },

    deleteLanguages (variables: UpdateModuleInput) {
      if (!this.updates.deletedLanguages?.length) return

      this.updates.deletedLanguages.forEach((lang: string) => {
        const deletedTextIds = this.module.texts!.filter(text => text.languagecode === lang).map(text => text!.id)
        deletedTextIds.forEach(id => variables.texts!.delete!.push(id))

        let contentId = null! as string | undefined

        switch (this.module.type) {
          case 'package':
            contentId = this.module.packages.find(p => p.languagecode === lang)?.id
            if (contentId) variables.packages!.delete!.push(contentId)
            break
          case 'download':
          case 'pdf':
          case 'media':
            if (this.module.downloads.length > 0 && hasOwn(this.module.downloads, 'files')) {
              contentId = (this.module.downloads as any).files.find((f: any) => f.languagecode === lang)?.id
              if (contentId) {
                variables.downloads!.update!.push({
                  id: this.module.downloads![0]!.id,
                  type: 'module_content',
                  files: {
                    delete: [contentId]
                  }
                })
              }
            }
            break
          case 'external':
            if (this.module.links.length > 0) {
              contentId = this.module.links.find(l => l.languagecode === lang)?.id
              if (contentId) variables.links!.delete!.push(contentId)
            }
            break
          case 'simpl':
          case 'test':
            this.deletableLanguageVariantTexts.push(lang)
            break
          default:
            break
        }
      })
    },

    async uploadImage (): Promise<void> {
      this.loading += 1
      this.uploadFile = this.module.keyVisualFile!
      const files = await this.upload()

      if (!files) {
        // TODO: Show error to user
        return
      }

      this.keyVisualId = +files[0].id!
      this.loading -= 1
    },

    cancel (): void {
      if (this.runId) {
        this.$router.push(`/${this.featureMode}/${this.runId}/contents`)
      } else {
        this.$router.push('/contents')
      }
    },

    async save (): Promise<void> {
      this.loading += 1

      if (this.module.keyVisualFile) {
        await this.uploadImage()
      } else {
        this.keyVisualId = this.module.keyVisualId
      }

      await this.$apollo.mutate({
        mutation: UPDATE_MODULE,

        variables: {
          data: this.getMutationVariables()
        }
      })

      if (this.deletableLanguageVariantTexts.length) {
        await this.$apollo.mutate({
          mutation: DELETE_CONTENT_TEXTS,

          variables: {
            data: {
              module_id: this.module.id,
              languages: this.deletableLanguageVariantTexts
            }
          }
        })
      }

      this.updates = { lang: {} } as any
      this.deletableLanguageVariantTexts = []

      this.$notification.publish('bottom', {
        message: this.$t('core.message.savedChanges'),
        type: 'success',
        color: 'success'
      })

      await this.$apollo.queries.module.refetch()

      this.loading -= 1
    }
  },

  async beforeRouteLeave (_to: any, _from: any, next: (target?: any) => void): Promise<void> {
    if (!this.hasChanged) {
      next()
      return
    }

    const userFeedback = await this.$confirm({
      message: this.$t('core.message.unsavedChanges'),
      buttons: [{
        text: this.$t('core.global.yes'),
        type: 'outlined',
        answer: false
      }, {
        text: this.$t('core.global.no'),
        answer: true
      }]
    })

    if (!userFeedback) {
      next()
      return
    }

    next(false)
  }
}
)
