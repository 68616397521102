import { render, staticRenderFns } from "./WordCloud.vue?vue&type=template&id=a954c374&scoped=true&"
import script from "./WordCloud.vue?vue&type=script&lang=ts&"
export * from "./WordCloud.vue?vue&type=script&lang=ts&"
import style0 from "./WordCloud.vue?vue&type=style&index=0&id=a954c374&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a954c374",
  null
  
)

export default component.exports