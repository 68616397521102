var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fill-height content-padding"},[_c('Headline',{staticClass:"headline",attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('module.global.headline'))+" ")]),_c('div',{staticClass:"d-flex mb-6",class:_vm.$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'},[_c('v-spacer'),(_vm.languageTags.length)?_c('v-autocomplete',{staticClass:"flex-grow-0 pb-3 pt-2 pr-1",staticStyle:{"min-width":"200px"},attrs:{"clearable":"","items":_vm.languageTags,"label":_vm.$t('core.global.language'),"filter":_vm.tagSelectFilter,"item-text":"name","item-value":"identifier","hide-details":""},model:{value:(_vm.temporaryLanguage),callback:function ($$v) {_vm.temporaryLanguage=$$v},expression:"temporaryLanguage"}}):_vm._e(),(!_vm.$vuetify.breakpoint.xs)?_c('v-divider',{staticClass:"mx-5 mb-2 mt-4",attrs:{"vertical":""}}):_vm._e(),_c('SearchFilterBar',{staticClass:"flex-grow-0 px-4",attrs:{"view-selection":"","search-value":_vm.searchValue,"view":_vm.selectedView},on:{"update:searchValue":function($event){_vm.searchValue=$event},"update:search-value":function($event){_vm.searchValue=$event},"update:view":function($event){_vm.selectedView=$event}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-btn',{attrs:{"text":!_vm.filterBy.length,"outlined":!_vm.filterBy.length,"color":_vm.filterBy.length > 0 ? 'primary' : undefined},on:{"click":function($event){_vm.showFilter = true}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-filter-variant ")]),_vm._v(" "+_vm._s(_vm.$t('core.global.filter'))+" ")],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.$store.state.auth.user.is_super)?_c('v-btn',_vm._g({staticClass:"ml-4",attrs:{"fab":"","small":""},on:{"click":function($event){_vm.showImportContentDialog = true}}},on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v(" mdi-upload ")])],1):_vm._e()]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('core.action.import')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"can",rawName:"v-can",value:('module-create'),expression:"'module-create'"}],staticClass:"ml-4",attrs:{"color":"primary","fab":"","small":""},on:{"click":_vm.add}},on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('module.action.createModule')))])])]},proxy:true}])})],1),_c('v-slide-x-transition',{attrs:{"mode":"out-in"}},[_c('v-data-table',{key:"table-view",class:{ 'fixed-table-layout': _vm.selectedView === 'tiles' },attrs:{"loading":_vm.loading > 0,"headers":_vm.computedHeaders,"items":_vm.presentedData,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"server-items-length":_vm.count,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:page":function($event){_vm.page=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row justify-space-between align-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticStyle:{"overflow":"hidden","text-overflow":"ellipsis","white-space":"nowrap"},on:{"click":function($event){return _vm.handleRowClick(item)}}},on),[_c('a',{staticStyle:{"color":"unset"}},[_vm._v(" "+_vm._s(item.name)+" ")]),(_vm.isFallbackLanguage(item, _vm.temporaryLanguage))?_c('v-chip',{staticClass:"ma-2",attrs:{"x-small":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-translate ")]),_vm._v(" "+_vm._s(_vm.isFallbackLanguage(item, _vm.temporaryLanguage))+" ")],1):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('run.action.openDetails')))])]),(_vm.$vuetify.breakpoint.xs)?_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"dark":_vm.$vuetify.theme.dark,"icon":""}},on),[_c('v-icon',[_vm._v(" mdi-dots-vertical ")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',[_c('v-btn',{directives:[{name:"can",rawName:"v-can",value:('module-create'),expression:"'module-create'"}],attrs:{"icon":"","to":("/content/" + (item.id) + "/edit/basic")}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-tune ")])],1)],1),_c('v-list-item',[_c('v-btn',{attrs:{"icon":"","to":("content-preview/" + (item.id)),"target":"_blank"}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-television-play ")])],1)],1),_c('v-list-item',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.remove(item.id, item.name)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)],1)],1)],1):_vm._e()],1)]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.updated_at ? _vm.$d(item.updated_at, 'short') : ''))])]}},{key:"item.languages",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"white--text",attrs:{"color":_vm.stringToColor(item.languagecode),"small":""}},[_vm._v(" "+_vm._s(item.languagecode)+" ")]),_vm._l((_vm.getModuleLanguageVariants(item)),function(language,index){return (index < 2 && language !== item.languagecode)?_c('v-chip',{key:index,staticClass:"white--text",staticStyle:{"margin-left":"-10px"},attrs:{"color":language ? _vm.stringToColor(language) : '',"small":""}},[_vm._v(" "+_vm._s(language)+" ")]):_vm._e()}),(_vm.getModuleLanguageVariants(item).length > 2)?_c('v-chip',{staticStyle:{"margin-left":"-10px"},attrs:{"small":""}},[_vm._v(" +"+_vm._s(_vm.getModuleLanguageVariants(item).length - 2)+" ")]):_vm._e()]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{style:({ color: _vm.getModuleFormat(item).textColor || '#fff' }),attrs:{"outlined":_vm.$vuetify.breakpoint.xsOnly,"small":_vm.$vuetify.breakpoint.xsOnly,"color":_vm.getModuleFormat(item) && _vm.getModuleFormat(item).color}},[_c('v-icon',{staticClass:"ml-0",attrs:{"small":"","left":""}},[_vm._v(" "+_vm._s(_vm.getModuleFormat(item).icon)+" ")]),_c('span',[_vm._v(_vm._s(_vm.$t(("core.contentTypes." + (item.type)))))])],1)]}},{key:"item.tracking_type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("module.trackingTypes." + (item.tracking_type))))+" ")]}},{key:"item.duration",fn:function(ref){
var item = ref.item;
return [(item.duration)?_c('div',[_vm._v(" "+_vm._s(item.duration)+" min. ")]):_vm._e()]}},(!_vm.$vuetify.breakpoint.xs)?{key:"item.actions-cms",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"actions-cms"},[(item.type === 'simpl' || item.type === 'test')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"can",rawName:"v-can",value:('module-create'),expression:"'module-create'"}],staticClass:"mr-2",attrs:{"small":"","icon":"","href":("/cms/" + (item.id) + "/edit/" + (item.languagecode)),"target":"_blank"}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('module.edit.editContentNewTab')))])]):_vm._e()],1)]}}:null,{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"actions"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"can",rawName:"v-can",value:('module-create'),expression:"'module-create'"}],staticClass:"mr-2",attrs:{"small":"","icon":"","to":("/content/" + (item.id) + "/edit/basic")}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-tune ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('run.action.openDetails')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"small":"","icon":"","to":("content-preview/" + (item.id)),"target":"_blank"}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-television-play ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('core.action.preview')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"can",rawName:"v-can",value:('module-delete'),expression:"'module-delete'"}],staticClass:"mr-2",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.remove(item.id, item.name)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('module.action.deleteModule')))])])],1)]}}],null,true)},[(_vm.selectedView !== 'list')?_c('template',{slot:"body"},[_c('tr',[_c('td',{attrs:{"colspan":_vm.computedHeaders.length}},[_c('v-container',{staticClass:"mx-0",attrs:{"fluid":""}},[_c('v-row',_vm._l((_vm.presentedData),function(module,index){return _c('v-col',{key:index,attrs:{"cols":"12","sm":"6","md":"3","lg":"3","xl":"2"}},[_c('ItemTile',{attrs:{"item":module,"to":("/content/" + (module.id) + "/edit/basic")},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{directives:[{name:"can",rawName:"v-can",value:('module-create'),expression:"'module-create'"}],staticClass:"mr-6",attrs:{"icon":"","to":("/content/" + (item.id) + "/edit/basic")},on:{"click":function($event){$event.stopPropagation();}}},[_c('v-icon',{attrs:{"small":_vm.$vuetify.breakpoint.xs}},[_vm._v(" mdi-tune ")])],1),_c('v-btn',{staticClass:"mr-6",attrs:{"icon":"","to":("content-preview/" + (item.id)),"target":"_blank"},on:{"click":function($event){$event.stopPropagation();}}},[_c('v-icon',{attrs:{"small":_vm.$vuetify.breakpoint.xs}},[_vm._v(" mdi-television-play ")])],1),(item.type === 'simpl' || item.type === 'test')?_c('v-btn',{directives:[{name:"can",rawName:"v-can",value:('module-create'),expression:"'module-create'"}],staticClass:"mr-6",attrs:{"icon":"","to":("/cms/" + (item.id) + "/edit/" + (item.languagecode))},on:{"click":function($event){$event.stopPropagation();}}},[_c('v-icon',{attrs:{"small":_vm.$vuetify.breakpoint.xs}},[_vm._v(" mdi-pencil ")])],1):_vm._e(),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.preventDefault();return _vm.remove(item.id, item.name)}}},[_c('v-icon',{attrs:{"small":_vm.$vuetify.breakpoint.xs}},[_vm._v(" mdi-delete ")])],1)]}},{key:"languages",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"white--text",attrs:{"color":_vm.stringToColor(item.languagecode),"small":""}},[_vm._v(" "+_vm._s(item.languagecode)+" ")]),_vm._l((_vm.getModuleLanguageVariants(item)),function(language,i){return (i < 2 && language !== item.languagecode)?_c('v-chip',{key:i,staticClass:"white--text",staticStyle:{"margin-left":"-10px"},attrs:{"color":language ? _vm.stringToColor(language) : '',"small":""}},[_vm._v(" "+_vm._s(language)+" ")]):_vm._e()}),(_vm.getModuleLanguageVariants(item).length > 2)?_c('v-chip',{staticStyle:{"margin-left":"-10px"},attrs:{"small":""}},[_vm._v(" +"+_vm._s(_vm.getModuleLanguageVariants(item).length - 2)+" ")]):_vm._e()]}}],null,true)})],1)}),1)],1)],1)])]):_vm._e()],2)],1),_c('EditModuleDialog',{attrs:{"language-tags":_vm.languageTags},on:{"saved":_vm.refetchModules},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}}),_c('FilterView',{attrs:{"filter-by":_vm.filterBy,"filter-options":_vm.filterOptions},on:{"update:filterBy":function($event){_vm.filterBy=$event},"update:filter-by":function($event){_vm.filterBy=$event}},model:{value:(_vm.showFilter),callback:function ($$v) {_vm.showFilter=$$v},expression:"showFilter"}}),_c('ImportContentDialog',{attrs:{"type":"module"},on:{"success":_vm.onImport},model:{value:(_vm.showImportContentDialog),callback:function ($$v) {_vm.showImportContentDialog=$$v},expression:"showImportContentDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }