















import Vue from 'vue'
import { Placement } from '@simpl/core/types/graphql'
import RankingFlag from './ranking/RankingFlag.vue'

export default Vue.extend({
  name: 'Podium',

  components: {
    RankingFlag
  },
  props: {
    placements: Array as () => Placement[]
  },

  computed: {
    winners (): Placement[] {
      return this.placements.slice(0, 3)
    }
  },
  methods: {
    getRank (index: number): string {
      const ranks = ['first', 'second', 'third']
      return ranks[index] || ''
    },
    getText (placement: Placement): string {
      if (placement.firstname && placement.lastname) {
        return `${placement.firstname}  ${placement.lastname}`
      }
      if (placement.firstname) {
        return `${placement.firstname}`
      }
      if (placement.lastname) {
        return `${placement.lastname}`
      }
      if (placement.username) {
        return `${placement.username}`
      }
      return ''
    },
    getSecondaryText (score: number): string {
      return (!score && score !== 0) ? '' : `${score} ${this.$t('core.global.score')}`
    }
  }
})
