






























































































































































































































































































































































import mixins from 'vue-typed-mixins'
import StringToColor from '@simpl/core/mixins/utils/StringToColor'
import ActiveDomainTags from '@simpl/core/mixins/apollo/ActiveDomainTags'
import TagSelectFilter from '@simpl/core/mixins/utils/TagSelectFilter'
import FileUpload from '@simpl/core/mixins/apollo/FileUpload'
import PackageUpload from '@simpl/core/mixins/apollo/PackageUpload'
import FileUploader from '@simpl/core/components/uploader/FileUploader.vue'
import ImportContentTextsDialog from '../components/ImportContentTextsDialog.vue'

import { moduleFormats } from '@simpl/core/utils/package-formats'

import { getTextForLanguage, getTextForTemporaryUserLanguage, getTextForUserLanguage } from '@simpl/core/utils/text'
// @ts-ignore
import { ModuleEditUpdates } from './ModuleEdit.vue'
import {
  Module,
  ModuleFile,
  ModulePackageVerification,
  Tag,
  File as GraphqlFile,
  ModulePackage, Query
} from '@simpl/core/types/graphql'
import { hasOwn } from '@simpl/core/utils/core'
import { getDateTime } from '@simpl/core/utils/export'
import { TagWithName } from '@simpl/core/types/extended-types'
import { RUN_DASHBOARD } from '../../runs/graphql'
import {
  AUTO_TRANSLATE_MODULE,
  DELETE_CONTENT_TEXTS,
  GET_AUTO_TRANSLATE_TARGET_LANGS,
  IS_AUTO_TRANSLATE_SOURCE_LANG
} from '../graphql'

export default mixins(
  StringToColor,
  ActiveDomainTags,
  TagSelectFilter,
  FileUpload,
  PackageUpload
).extend({
  name: 'ModuleEditVariants',

  components: {
    FileUploader,
    ImportContentTextsDialog
  },

  props: {
    module: Object as () => Module,
    updates: Object as () => ModuleEditUpdates,
    masterLanguage: {
      type: String,
      required: true
    },
    masterLanguageTag: Object as () => Tag,
    translatableLanguages: Array as () => any[],
    languages: Array as () => string[],
    languageTags: Array as () => TagWithName[]
  },

  data () {
    return {
      masterLocked: false,

      showLanguageSelection: false,

      uploadKey: null! as string,
      expanded: null! as number,

      apiUrl: process.env.VUE_APP_API_URL || '',
      processing: false,

      newLanguageCode: '',
      newCountryCode: '',

      importContentTextsDialog: false,
      importLanguagecode: '',

      autoTranslate: false,
      isAutoTranslateSourceLang: false,
      autoTranslateTargetLangs: null! as object
    }
  },

  computed: {
    newLcCc (): string {
      return this.newCountryCode.length && this.newCountryCode !== '-'
        ? `${this.newLanguageCode}-${this.newCountryCode}`
        : this.newLanguageCode
    },
    isAutoTranslatable (): boolean {
      if (this.newLanguageCode === 'en') return true
      const value = this.isAutoTranslateSourceLang && (
        !this.newLanguageCode.length || Object.values(this.autoTranslateTargetLangs).includes(this.newLanguageCode)
      )
      if (this.autoTranslate && !value) this.autoTranslate = false
      return value
    },
    showTranslationInProgress (): boolean {
      return !!this.module?.properties?.bt_translation_active
    },
    masterTitle: {
      get (): string {
        if (!this.masterLanguage) return ''

        if (this.updates.name) {
          return this.updates.name
        }

        return getTextForLanguage(this.module, this.masterLanguage)
      },
      set (v: string) {
        this.setUpdateValue('name', v)
      }
    },

    languageTitle: {
      get (): string {
        if (!this.translatedLang) return ''

        if (this.updates.lang[this.translatedLang]?.name) {
          return this.updates.lang[this.translatedLang].name
        }

        return getTextForLanguage(this.module, this.translatedLang)
      },
      set (v: string) {
        this.setUpdateValue('name', v, this.translatedLang)
      }
    },

    masterDescription: {
      get (): string {
        if (this.updates.lang[this.masterLanguage]?.description) {
          return this.updates.lang[this.masterLanguage].description
        }

        return getTextForLanguage(this.module, this.masterLanguage, 'description')
      },
      set (v: string) {
        this.setUpdateValue('description', v)
      }
    },

    languageDescription: {
      get (): string {
        if (!this.translatedLang) return ''

        if (this.updates.lang[this.translatedLang]?.description) {
          return this.updates.lang[this.translatedLang].description
        }

        return getTextForLanguage(this.module, this.translatedLang, 'description')
      },
      set (v: string) {
        this.setUpdateValue('description', v, this.translatedLang)
      }
    },

    masterLink: {
      get (): string {
        if (!this.masterLanguage) return ''

        if (this.updates.link) {
          return this.updates.link
        }

        return this.module.links!.find(l => l!.is_master)!.url!
      },
      set (v: string) {
        this.setUpdateValue('link', v)
      }
    },

    languageLink: {
      get (): string {
        if (!this.translatedLang) return ''

        if (this.updates.lang[this.translatedLang]?.link) {
          return this.updates.lang[this.translatedLang].link
        }

        return this.module.links!.find(l => l!.languagecode === this.translatedLang)?.url || ''
      },
      set (v: string) {
        this.setUpdateValue('link', v, this.translatedLang)
      }
    },

    moduleFormat (): Record<string, any> | undefined {
      return moduleFormats.find(f => f.key === this.module.type)
    },

    acceptedFileTypes (): string {
      return (this.moduleFormat && this.moduleFormat.accept) || '*'
    },

    packageInfo (): Record<string, any> | null {
      const masterPackage = this.module.packages.filter(pkg => pkg.is_master === true)[0]
      return masterPackage
        ? {
          name: masterPackage.properties?.original_package_name || 'n/a',
          size: masterPackage.properties?.original_package_size
        }
        : null
    },

    langPackageInfo (): Record<string, any> | null {
      const langPackage = this.module.packages.find(pkg => pkg.languagecode === this.translatedLang)
      return langPackage
        ? {
          name: langPackage.properties?.original_package_name || 'n/a',
          size: langPackage.properties?.original_package_size
        }
        : null
    },

    translatedLang (): string {
      return this.languages[this.expanded] || null! as string
    },

    showImportExportButtons (): boolean {
      return ['simpl', 'test'].includes(this.module.type)
    }
  },

  methods: {
    getTextForUserLanguage,

    getLanguageCountryName (language: string): string {
      const segments = language.split('-')
      const languageName = this.activeDomainLanguageTags.filter((lang: Tag) => lang.identifier === segments[0])[0].name
      return (segments.length === 1)
        ? `${language} – ${languageName}`
        : `${language} – ${languageName} (${this.activeDomainMarketTags.filter((market: Tag) => market.identifier === segments[1])[0].name})`
    },
    checkLcCcExists (): boolean {
      return !!this.languages.filter((language: string) => language === this.newLcCc).length ||
        this.newLcCc === this.masterLanguage
    },
    async addLanguage (language: string) {
      this.$set(this.languages, this.languages.length, language)
      this.showLanguageSelection = false
      this.expanded = this.languages.length - 1

      this.$emit('save')

      if (this.autoTranslate) {
        await this.$apollo.mutate({
          mutation: AUTO_TRANSLATE_MODULE,

          variables: {
            data: {
              module_id: this.module.id,
              source_lang: this.masterLanguage,
              target_lang: language
            }
          }
        })
      }
    },
    getMasterFile (file: ModuleFile): GraphqlFile & { name: string } {
      return {
        ...file.masterFile!,
        name: file.masterFile!.filename
      }
    },

    getLanguageFile (file: ModuleFile): (GraphqlFile & { name: string }) | null {
      const langData = this.updates.lang[this.translatedLang]
      if (langData && hasOwn(langData, 'file')) return langData.file

      const langFile = file.files?.find(f => f!.languagecode === this.translatedLang)
      if (!langFile) return null

      return {
        ...langFile,
        name: langFile.filename
      }
    },

    getPackageFile (packages: ModulePackage[], master?: boolean): ModulePackage | null {
      if (master) return packages.find(p => p.is_master) || null

      return packages.find(p => p.languagecode === this.translatedLang) || null
    },

    async onMasterFileChange (file: File): Promise<void> {
      await this.updateFile(file)
    },

    async onLangFileChange (file: File | null): Promise<void> {
      const oldFile = this.getLanguageFile(this.module.downloads![0]!)
      if (oldFile && !this.hasUpdateValue('oldFileId', this.translatedLang)) {
        this.setUpdateValue('oldFileId', oldFile.id, this.translatedLang)
      }

      if (!file) {
        this.setUpdateValue('file', null, this.translatedLang)
        return
      }

      await this.updateFile(file, this.translatedLang)
    },

    async onLangPackageChange (file: File | null): Promise<void> {
      const oldPackage = this.getPackageFile(this.module.packages as any)
      if (oldPackage && !this.hasUpdateValue('oldPackageId', this.translatedLang)) {
        this.setUpdateValue('oldPackageId', oldPackage.id, this.translatedLang)
      }

      if (!file) {
        this.setUpdateValue('package', null, this.translatedLang)
        return
      }

      await this.updateFile(file, this.translatedLang)
    },

    async updateFile (file: File, lang?: string): Promise<void> {
      this.uploadContentCategory = 'module_content'
      this.uploadLanguageCode = lang || this.masterLanguage
      this.uploadKey = lang ? 'lang' : 'master'
      this.uploadFile = file

      if (!this.moduleFormat?.uploadAsPackage) {
        const uploadedFiles = await this.upload()
        if (uploadedFiles) {
          this.setUpdateValue('file', uploadedFiles[0], lang)
        }
      } else {
        const properties = JSON.stringify({
          original_package_name: file.name,
          original_package_size: file.size
        })

        const uploadedPackage = await this.uploadPackage() as ModulePackageVerification

        if (uploadedPackage) {
          this.setUpdateValue('package', {
            ...uploadedPackage,
            properties: properties
          }, lang)
        }
      }

      this.uploadLanguageCode = null!
      this.uploadFile = null
    },

    deleteLang (language: string) {
      const index = this.languages.findIndex((lc: string) => lc === language)
      this.languages.splice(index, 1)

      if (!this.updates.deletedLanguages) {
        this.$set(this.updates, 'deletedLanguages', [])
      }
      this.updates.deletedLanguages.push(language)
    },

    isDeleted (language: Tag) {
      return !!this.updates.deletedLanguages?.find((lang: string) => lang === language.identifier)
    },

    hasUpdateValue (key: string, lang?: string): boolean {
      const data = lang ? this.updates.lang[lang] : this.updates
      return data && hasOwn(data, key)
    },

    setUpdateValue (key: string, value: any, lang?: string): void {
      if (lang) {
        if (!this.updates.lang[lang]) {
          this.$set(this.updates.lang, lang, {})
        }
        this.$set(this.updates.lang[lang], key, value)
      } else {
        this.$set(this.updates, key, value)
      }
    },

    importContentTexts (lang: string) {
      this.importLanguagecode = lang
      this.importContentTextsDialog = true
    },

    async exportContentTexts (lang: string) {
      this.processing = true
      const headers = { authorization: `Bearer ${this.$store.state.auth.token}` }
      await fetch(`${this.apiUrl}/download/content/text/${this.module.id}/${lang}/xx/xliff`, { headers })
        .then((response) => response.blob())
        .then(data => {
          const date = getDateTime()
          this.saveBlobAsFile(`${getDateTime()}_export_${getTextForTemporaryUserLanguage(this.module)}_(${this.module.identifier})_${lang}.xml`, data)
        }).catch((err) => {
          console.log(err)
        })
      this.processing = false
    },

    saveBlobAsFile (fileName: string, file: Blob) {
      if (typeof (Blob) !== 'undefined') { // using Blob
        const downloadLink = document.createElement('a')
        downloadLink.download = fileName
        if (window.webkitURL != null) {
          downloadLink.href = window.webkitURL.createObjectURL(file)
        } else {
          downloadLink.href = window.URL.createObjectURL(file)
          downloadLink.onclick = document.body.removeChild(event.target)
          downloadLink.style.display = 'none'
          document.body.appendChild(downloadLink)
        }
        downloadLink.click()
      } else {
        const pp = document.createElement('a')
        pp.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(file.toString()))
        pp.setAttribute('download', fileName)
        pp.onclick = document.body.removeChild(event.target)
        pp.click()
      }
    }
  },

  apollo: {
    isAutoTranslateSourceLang: {
      query: IS_AUTO_TRANSLATE_SOURCE_LANG,

      variables (): object {
        return {
          languagecode: this.masterLanguage
        }
      },

      fetchPolicy: 'cache-first',

      update (result: Query) {
        return result.isAutoTranslateSourceLang
      }
    },
    autoTranslateTargetLangs: {
      query: GET_AUTO_TRANSLATE_TARGET_LANGS,

      skip ():boolean {
        return !this.isAutoTranslateSourceLang
      },

      fetchPolicy: 'cache-first',

      update (result: Query) {
        return result.getAutoTranslateTargetLangs
      }
    }
  }
})
