
















import Vue from 'vue'
import CompactGraphTypeSelect from './CompactGraphTypeSelect.vue'
import ApexCharts, { ApexOptions } from 'apexcharts'
import deepmerge from 'deepmerge'
import { mapTrackingData } from '../utils/parser'
import parseBar from '../parsers/BarParser'
import parseGroupedBar from '../parsers/GroupedBarParser'
import parseLine from '../parsers/LineParser'
import parsePie from '../parsers/PieParser'
import apexOptions from '../configs/ApexOptions'
import { RunEvaluation } from '@simpl/core/types/graphql'
import {
  SESSION_TRACKING_STORED,
  TRACKINGS
} from '@simpl/base-management/runs/graphql'

type ApexAxisChartSeries = {
  name?: string
  type?: string
  color?: string
  data:
    | (number | null)[]
    | { x: any; y: any; fillColor?: string; strokeColor?: string }[]
    | [number, number | null][]
    | [number, (number | null)[]][]
}[]

type ApexNonAxisChartSeries = number[]

export default Vue.extend({
  name: 'RunEvaluationChart',

  components: {
    CompactGraphTypeSelect
  },

  props: {
    trackingVisualization: Object,
    properties: Object,
    useTestData: Boolean,
    height: Number
  },

  data: () => ({
    chart: null! as ApexCharts,
    internalDiagramType: null! as string,
    filteredDiagramTypes: [] as string[],

    series: [] as ApexAxisChartSeries | ApexNonAxisChartSeries,

    isChartInitialized: false,
    apolloTrackings: null! as Record<string, any>[],
    animateTransition: true,
    animateDynamicTransition: true,
    timeout: null! as number
  }),

  computed: {
    diagramType (): null | string {
      return this.internalDiagramType
    },

    runId (): null | string {
      return this.$router.currentRoute.params.runIdentifier
    },

    colors (): string[] {
      return this.properties.colors
        ? this.properties.colors
        : this.properties.palettes
          ? this.properties.palettes[0]
          : null
    },

    mappedParameter (): Record<string, any>[] {
      return this.trackingVisualization.items.map(
        (item: Record<string, any>) => {
          return {
            run_id: item.run?.id ? item.run.id : this.runId,
            module_id: item.module?.id,
            objective: item.objective,
            site: item.site,
            key: item.key
          }
        }
      )
    },

    apolloChartOptions (): ApexOptions {
      if (!this.apolloTrackings) {
        return null! as ApexOptions
      }
      return this.getChartOptions(this.apolloTrackings)
    },

    hasCompactGraphTypeSelect (): boolean {
      return this.filteredDiagramTypes.length > 1
    }
  },

  watch: {
    apolloTrackings: 'mergeAndUpdate',
    properties: 'mergeAndUpdate',
    useTestData: 'mergeAndUpdate',
    height: 'mergeAndUpdate'
  },

  beforeDestroy () {
    if (this.chart) {
      this.chart.destroy()
    }
  },

  methods: {
    firstRender (options: ApexOptions) {
      this.isChartInitialized = true
      this.chart = new ApexCharts(document.querySelector('#chart'), options)
      this.chart.render()
    },

    updateChart (options: ApexOptions) {
      try {
        this.chart.updateOptions(options)
      } catch (e) {
        alert(e)
      }
    },

    updateDiagramTypes (
      diagramTypes: string[],
      availableDiagramTypes: string[]
    ) {
      this.filteredDiagramTypes = diagramTypes.filter((type: string) =>
        availableDiagramTypes.includes(type)
      )

      if (!this.filteredDiagramTypes.includes(this.internalDiagramType)) {
        this.internalDiagramType = this.filteredDiagramTypes[0]
      }
    },

    mergeAndUpdate () {
      if (
        this.apolloTrackings &&
        Object.keys(this.properties).length !== 0 &&
        this.properties.constructor === Object
      ) {
        this.updateDiagramTypes(
          this.properties.diagramTypes,
          this.properties.availableDiagramTypes
        )
        const chartOptions = this.getChartOptions(this.apolloTrackings)
        const mergedOptions = this.mergePropertiesWithChartOptions(
          chartOptions,
          this.properties
        )
        if (!this.isChartInitialized) {
          this.firstRender(mergedOptions)
        } else {
          this.updateChart(mergedOptions)
        }
      }
    },

    mergePropertiesWithChartOptions (
      chartOptions: ApexOptions,
      properties: Record<string, any>
    ) {
      const args = []
      const bottomMargin = this.hasCompactGraphTypeSelect ? 90 : 50
      const chartHeight = this.height - bottomMargin

      args.push({
        chart: {
          height: `${chartHeight}px`
        }
      })
      if (this.animateTransition) {
        args.push({
          chart: {
            animations: {
              enabled: true
            }
          }
        })
        this.animateTransition = false
      }

      if (!this.animateDynamicTransition) {
        args.push({
          chart: {
            animations: {
              enabled: true,
              easing: 'linear',
              dynamicAnimation: {
                enabled: false,
                speed: 1000
              }
            }
          }
        })
        this.animateDynamicTransition = true
      }

      if (typeof properties.distributed === 'boolean') {
        args.push({
          plotOptions: {
            bar: {
              distributed: properties.distributed
            }
          }
        })
      }

      if (properties.categories) {
        let hasString = false
        let hasNumber = false
        let categories = properties.categories

        properties.categories.forEach((category: string | number) => {
          typeof category === 'string' ? hasString = true : hasNumber = true
        })

        // convert numbers to strings
        if (hasString && hasNumber) {
          categories = properties.categories.map((category: string | number) => {
            return typeof category === 'number' ? String(category) : category
          })
        }

        args.push({
          xaxis: {
            categories: categories,
            type: hasString ? 'category' : 'numeric'
          },
          labels: categories
        })
      }
      if (properties.xAxisTitle) {
        args.push({
          xaxis: {
            title: {
              text: properties.xAxisTitle
            }
          }
        })
      }
      if (properties.yAxisTitle) {
        args.push({
          yaxis: {
            title: {
              text: properties.yAxisTitle
            }
          }
        })
      }

      if (properties.palettes) {
        args.push({
          palettes: properties.palettes
        })
      }

      return deepmerge.all([chartOptions, ...args]) as any
    },

    onGraphTypeSelect (v: string) {
      this.internalDiagramType = v
      this.animateDynamicTransition = false
      this.mergeAndUpdate()
    },

    getChartOptions (trackings: Record<string, any>[]): ApexOptions {
      if (!trackings || !trackings.length) {
        return null! as ApexOptions
      }
      const firstItem = this.trackingVisualization.items[0]
      const mappedData = mapTrackingData({
        trackings: trackings,
        content: firstItem.content,
        adjustments: {
          categories: this.properties.categories,
          useTestData: this.useTestData,
          legend: this.properties?.legend // currently only used in for CDragAndDrop
        }
      })
      let options = null! as ApexOptions
      const defaultOption = apexOptions
      const labelColor = this.$vuetify.theme.dark ? '#ffffff' : '#000000'
      const type = this.internalDiagramType
        ? this.internalDiagramType
        : this.trackingVisualization.type

      let calculatedColors = this.colors

      if (mappedData[0] && mappedData[0].seriesData) {
        calculatedColors = this.calculateAdditionalColors(
          this.colors,
          mappedData[0].seriesData.length
        )
      }
      switch (type) {
        case 'bar':
          options = parseBar(mappedData, calculatedColors, this.properties, labelColor)
          break
        case 'groupedBar':
          options = parseGroupedBar(mappedData, calculatedColors, this.properties, labelColor)
          break
        case 'line':
          options = parseLine(mappedData, calculatedColors, this.properties, labelColor)
          break
        case 'pie':
        case 'donut':
          options = parsePie(type, mappedData, calculatedColors, this.properties)
          break
      }
      if (options) {
        return deepmerge.all([defaultOption, options])
      }
      return defaultOption
    },

    calculateColorLuminance (hex: string, lum: number): string {
      // validate hex string
      hex = String(hex).replace(/[^0-9a-f]/gi, '')
      if (hex.length < 6) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
      }
      lum = lum || 0

      // convert to decimal and change luminosity
      let rgb = '#'
      let c
      let i
      for (i = 0; i < 3; i++) {
        c = parseInt(hex.substr(i * 2, 2), 16)
        c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16)
        rgb += ('00' + c).substr(c.length)
      }

      return rgb
    },

    calculateAdditionalColors (colors: string[], dataLength: number): string[] {
      const additionalColors = [] as string[]
      let factor = 0
      for (let i = 0; i < dataLength; i++) {
        factor = Math.floor(i / colors.length)
        const currentColor = colors[i % colors.length]
        const newColor = this.calculateColorLuminance(
          currentColor,
          0.05 * factor
        )
        additionalColors.push(newColor)
      }
      return additionalColors
    },

    async refetch () {
      await this.$apollo.queries.apolloTrackings.refetch()
    }
  },

  apollo: {
    apolloTrackings: {
      query: TRACKINGS,
      fetchPolicy: 'no-cache',

      skip (): boolean {
        return !this.trackingVisualization
      },

      variables (): Record<string, any> {
        const parameter = this.mappedParameter

        return {
          data: parameter
        }
      },

      update (result: RunEvaluation): Record<string, any>[] {
        return result.trackings
      },

      error (error: Error): void {
        console.error(error)
      },

      loadingKey: 'loading',

      subscribeToMore: {
        document: SESSION_TRACKING_STORED,
        variables (): Record<string, any> {
          const parameter = this.mappedParameter[0]

          return {
            run_id: parameter.run_id,
            module_id: parameter.module_id,
            key: parameter.key
          }
        },

        updateQuery (previous: RunEvaluation, { subscriptionData }: any) {
          // TODO @cb: Woher weiß ich, welches das richtige Item ist?
          const currentSubTracking =
            subscriptionData.data?.sessionTrackingStored?.trackings?.[0]
          const currentApolloTracking = this.apolloTrackings[0].tracking_data
          const currentTrackingData = currentApolloTracking.find((tracking) => {
            return tracking.id === currentSubTracking.id
          })

          this.animateTransition = true
          // TODO @cb: Ich kann die Items nicht einfach ersetzen, die Items sind nicht gleich, nur die Value müsste weitergegeben werden
          this.refetch()
        }
      }
    }
  }
})
