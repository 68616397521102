import { app } from '@simpl/core/init-app'

export default function getAnnotations (item: Record<string, any>) {
  if (!hasAverage(item) && !hasSolution(item)) {
    return []
  }

  const annotations = []
  let offsetY = 0
  let value
  let text

  if (hasAverage(item) && hasSolution(item)) {
    offsetY = 12
  }

  if (hasAverage(item)) {
    value = item.average!
    text = 'Ø: ' + parseFloat(value.toFixed(2))
    annotations.push(getAnnotation(value, offsetY, text))
  }

  if (hasSolution(item)) {
    value = item.properties?.properties.exerciseSettings.solution
    text = `${app.$t('cms.properties.solution')}: ${value}`
    annotations.push(getAnnotation(value, -offsetY, text))
  }

  return annotations
}

function hasSolution (item: Record<string, any>): boolean {
  return item.properties?.properties?.exerciseSettings &&
    Object.hasOwn(item.properties?.properties?.exerciseSettings, 'solution')
}

function hasAverage (item: Record<string, any>): boolean {
  return Object.hasOwn(item, 'average')
}

function getAnnotation (value: number, offsetY: number, text: string): Record<string, any> {
  return {
    x: value,
    borderColor: '#6d7579',
    label: {
      style: {
        color: '#6d7579',
        fontSize: '16px'
      },
      offsetY: offsetY,
      orientation: 'horizontal',
      text: text
    }
  }
}
