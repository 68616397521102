



















































































































































































































































import mixins from 'vue-typed-mixins'
import FileUploader from '@simpl/core/components/uploader/FileUploader.vue'
import StringToColor from '@simpl/core/mixins/utils/StringToColor'
import FileUpload from '@simpl/core/mixins/apollo/FileUpload'
import { IMPORT_CONTENT_TEXTS } from '../graphql'
import { TranslateResult } from 'vue-i18n'

export default mixins(FileUpload, StringToColor).extend({
  name: 'ImportContentTextsDialog',

  components: {
    FileUploader
  },

  model: {},

  props: {
    value: Boolean,
    languagecode: String,
    moduleId: String
  },

  data () {
    return {
      accept: '',
      step: 1,
      steps: 2,
      loading: false,

      // This is a copy of the selected file
      // to provide user with infos such as filename and filesize
      uploadedFile: null as File | null,
      validated: false,
      validationFinished: false,
      validationResult: '' as string | TranslateResult,
      fileToImport: null,
      fileImportResult: {
        errors: [],
        warnings: [],
        imports: []
      } as Record<string, any[]>,
      selectedFileImportResult: [] as any[]
    }
  },

  computed: {
    saveButtonText (): TranslateResult {
      return this.step === 1 && this.uploadFile ? this.$t('core.action.upload') : this.$t('core.action.close')
    },
    canContinue (): boolean {
      return (this.step === 1 && this.uploadFile && this.validated) || this.step === 2
    },
    show: {
      get (): boolean {
        return this.value
      },
      set (v: boolean) {
        this.$emit('input', v)
      }
    }
  },

  watch: {
  },

  methods: {
    setUploadFile (file: File) {
      this.validated = false
      this.validationFinished = false
      this.uploadFile = file
      this.validateUploadFile(file)
    },
    async validateUploadFile (file: File) {
      let fileContent = null
      fileContent = await file.text().then(data => data)
      const domParser = new DOMParser()
      const dom = domParser.parseFromString(fileContent, 'text/xml')

      this.validated = !dom.getElementsByTagName('parsererror').length && dom.getElementsByTagName('file')[0].getAttribute('category') === 'Innovationmecom\\SimplContentStorage\\Models\\Content'

      this.validationResult = dom.documentElement.nodeName
      if (dom.getElementsByTagName('parsererror').length) {
        this.validationResult = this.$t('validation.mimes', { attribute: `"${file.name}"`, values: 'Xliff 1.2 XML' })
      }
      if (dom.getElementsByTagName('file')[0].getAttribute('category') !== 'Innovationmecom\\SimplContentStorage\\Models\\Content') {
        this.validationResult = this.$t('validation.not_in', { attribute: 'file.category' })
      }
      this.validationFinished = true
    },
    async nextStep () {
      if (this.step === 1) {
        let result = null
        if (this.uploadFile) {
          this.loading = true
          this.uploadedFile = this.uploadFile
          try {
            result = await this.$apollo.mutate({
              mutation: IMPORT_CONTENT_TEXTS,
              variables: this.getMutationVariables()
            })
            this.fileImportResult = result.data.importContentTexts
          } catch (e) {
            this.$notification.publish('bottom', {
              message: this.$t(e.message),
              type: 'error',
              color: 'error'
            })
          }
          this.uploadLanguageCode = null
          this.uploadFile = null
          this.loading = false
        }
        this.step += 1
      } else {
        this.close()
      }
    },

    getMutationVariables () {
      const variables = {
        name: 'content_text_upload',
        target_type: 'text_upload',
        selected_file: this.uploadFile,
        languagecode: this.languagecode
      }
      return {
        package: variables,
        module_id: this.moduleId
      }
    },

    close () {
      this.show = false
      this.$nextTick(() => {
        this.resetData()
      })
    },

    cancelUpload () {
      (this.$refs.fileUploader as any).reset()
      this.uploadedFile = null
    },

    resetData () {
      Object.assign(this.$data, (this.$options as any).data(), {})
    },

    selectFileImportResult (items: any[]) {
      this.selectedFileImportResult = items
    }
  }
})
