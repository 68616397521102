




































































































import Vue from 'vue'

import TrackingVisualizationItemComponent from './TrackingVisualizationItem.vue'
import SettingsAxis from '../components/settings/SettingsAxis.vue'
import SettingsColors from '../components/settings/SettingsColors.vue'
import SettingsTitle from '../components/settings/SettingsTitle.vue'
import SettingsDiagramType from '../components/settings/SettingsDiagramType.vue'
import SettingsCategories from '../components/settings/SettingsCategories.vue'
import SettingsNumericCategories from '../components/settings/SettingsNumericCategories.vue'
import SettingsOrientation from '../components/settings/SettingsOrientation.vue'
import SettingsDataSource from '../components/settings/SettingsDataSource.vue'

import { TRACKING_VISUALIZATION, UPDATE_TRACKING_VISUALIZATION } from '../graphql'
import { placeholderProperties, defaultProperties } from './config/defaultProperties'
import { getTextForTemporaryUserLanguage } from '@simpl/core/utils'
import parseTrackingVisualizationItem from '../parsers/TrackingVisualizationItemParser'
import { Query, Run, TrackingVisualization, TrackingVisualizationItem } from '@simpl/core/types/graphql'
import { GET_RUN_TEXTS_ONLY } from '@simpl/base-management/runs/graphql'

export default Vue.extend({
  name: 'VisualizationEdit',

  components: {
    TrackingVisualizationItemComponent,
    SettingsAxis,
    SettingsColors,
    SettingsTitle,
    SettingsDiagramType,
    SettingsCategories,
    SettingsNumericCategories,
    SettingsOrientation,
    SettingsDataSource
  },

  props: {
    visualizationId: [String, Number]
  },

  breadcrumbs () {
    const featureMode = (this.$route.path.substr(1, 6) === 'course') ? 'course' : 'run'
    const breadcrumbs: any[] = [{
      text: 'core.action.edit',
      to: null
    }]
    breadcrumbs.unshift({
      text: 'trackingVisualization.global.visualizations',
      to: `/${featureMode}/${this.runId}/evaluation/${this.evaluationId}/visualizations`
    })
    if (this.runId && getTextForTemporaryUserLanguage(this.run) !== 'display_name') {
      breadcrumbs.unshift({
        title: getTextForTemporaryUserLanguage(this.run),
        to: `/${featureMode}/${this.runId}/evaluations`
      })
    }
    breadcrumbs.unshift({
      text: `run.global.${featureMode}s`,
      to: `/${featureMode}s`
    })
    return breadcrumbs
  },

  data: function () {
    return {
      run: null! as Run,
      loading: false,
      trackingVisualization: null as TrackingVisualization | null,
      placeholderProperties: placeholderProperties,
      defaultProperties: defaultProperties,
      initialEditOnlyProperties: {
        dataSource: 'userData'
      },
      customEditOnlyProperties: {} as Record<string, any>,
      customProperties: {} as Record<string, any>,
      panel: -1,
      settingsColWidth: '500'
    }
  },

  computed: {
    componentId (): number {
      return this.$store.getters['cms/selectedComponent'].content?.id
    },
    runId (): null | string {
      return this.$router.currentRoute.params.runIdentifier
    },
    evaluationId (): null | string {
      return this.$router.currentRoute.params.evaluationIdentifier
    },
    isWordCloud (): boolean {
      return this.currentProperties.diagramTypes.includes('wordcloud')
    },
    expansionPanels (): Record<string, any> {
      return this.isWordCloud ? this.wordCloudExpansionPanels : this.defaultExpansionPanels
    },
    defaultExpansionPanels ():Record<string, any>[] {
      return [
        {
          header: this.$t('trackingVisualization.settings.title'),
          key: 'title',
          component: SettingsTitle,
          componentProperties: {
            title: this.currentProperties.title
          },
          updateFunction: this.update
        }, {
          header: this.$t('trackingVisualization.settings.axis'),
          key: 'axis',
          component: SettingsAxis,
          componentProperties: {
            xAxisTitle: this.currentProperties.xAxisTitle,
            yAxisTitle: this.currentProperties.yAxisTitle
          },
          updateFunction: this.update
        }, {
          header: this.$t('trackingVisualization.settings.diagramType'),
          key: 'diagramType',
          component: SettingsDiagramType,
          componentProperties: {
            all: this.currentProperties.diagramTypes,
            selected: this.currentProperties.availableDiagramTypes.filter(
              (diagramType:string) => this.currentProperties.diagramTypes.includes(diagramType)
            )
          },
          updateFunction: this.handleUpdateSelectedDiagramTypes
        }, {
          header: this.$t('trackingVisualization.settings.color'),
          key: 'color',
          component: SettingsColors,
          componentProperties: {
            palettes: this.currentProperties.palettes,
            distributable: this.currentProperties.distributable,
            isDistributed: this.currentProperties.distributed,
            colors: this.currentProperties.colors
          },
          updateFunction: this.update
        }, {
          header: this.$t('trackingVisualization.settings.category'),
          key: 'category',
          ...this.hasNumericCategories && {
            component: SettingsNumericCategories,
            componentProperties: {
              tickAmountData: this.currentProperties.tickAmountData
            }
          },
          ...this.hasDefaultCategories && {
            component: SettingsCategories,
            componentProperties: {
              children: this.currentProperties.categories
            }
          },
          updateFunction: this.handleUpdateCategories
        }, ...(this.currentProperties?.legend
          ? [
            {
              header: this.$t('trackingVisualization.settings.legend'),
              key: 'legend',
              component: SettingsCategories, // used as SettingsLegend
              componentProperties: {
                children: this.currentProperties.legend
              },
              updateFunction: this.handleUpdateLegend
            }
          ]
          : []), {
          header: this.$t('trackingVisualization.settings.dataSource'),
          key: 'dataSource',
          component: SettingsDataSource,
          componentProperties: {
            dataSources: this.currentProperties.dataSources,
            dataSource: this.editOnlyProperties.dataSource
          },
          updateFunction: this.updateEditOnly
        }
      ]
    },
    wordCloudExpansionPanels ():Record<string, any>[] {
      return [
        {
          header: this.$t('trackingVisualization.settings.title'),
          key: 'title',
          component: SettingsTitle,
          componentProperties: {
            title: this.currentProperties.title
          },
          updateFunction: this.update
        }, {
          header: this.$t('trackingVisualization.settings.orientations'),
          key: 'scale',
          component: SettingsOrientation,
          componentProperties: {
            orientations: this.currentProperties.orientations,
            orientation: this.currentProperties.orientation
          },
          updateFunction: this.update
        }, {
          header: this.$t('trackingVisualization.settings.color'),
          key: 'color',
          component: SettingsColors,
          componentProperties: {
            palettes: this.currentProperties.palettes,
            distributable: this.currentProperties.distributable,
            isDistributed: this.currentProperties.distributed,
            colors: this.currentProperties.colors
          },
          updateFunction: this.update
        }, {
          header: this.$t('trackingVisualization.settings.dataSource'),
          key: 'dataSource',
          component: SettingsDataSource,
          componentProperties: {
            dataSources: this.currentProperties.dataSources,
            dataSource: this.editOnlyProperties.dataSource
          },
          updateFunction: this.updateEditOnly
        }
      ]
    },
    editOnlyProperties  (): Record<string, any> {
      return {
        ...this.initialEditOnlyProperties,
        ...this.customEditOnlyProperties
      }
    },
    currentProperties (): Record<string, any> {
      return {
        ...this.placeholderProperties,
        ...this.defaultProperties,
        ...this.parsedTrackingProperties,
        ...this.trackingVisualizationProperties,
        ...this.customProperties
      }
    },
    firstItem (): TrackingVisualizationItem | {} {
      if (
        !this.trackingVisualization?.items ||
        this.trackingVisualization?.items?.length === 0
      ) return {}

      return this.trackingVisualization.items[0]
    },
    parsedTrackingProperties (): Record<string, any> {
      if (Object.keys(this.firstItem).length === 0 && this.firstItem.constructor === Object) return {}
      const parsed = parseTrackingVisualizationItem(this.firstItem as TrackingVisualizationItem)
      if (!parsed.title) parsed.title = this.trackingVisualization!.identifier
      return parsed
    },
    trackingVisualizationProperties (): Record<string, any> {
      if (!this.trackingVisualization) return {}
      return this.trackingVisualization.properties
    },
    pristine (): Boolean {
      return (Object.keys(this.customProperties).length === 0 && this.customProperties.constructor === Object) ||
        JSON.stringify(this.customProperties) === JSON.stringify(this.trackingVisualizationProperties)
    },
    hasNumericCategories (): Boolean {
      return this.parsedTrackingProperties?.hasNumericCategories
    },
    hasDefaultCategories (): Boolean {
      return !this.parsedTrackingProperties?.hasNumericCategories
    }
  },

  watch: {
    trackingVisualization (v) {
      if (v.items?.length > 1) {
        this.customProperties.distributable = false
        this.customProperties.distributed = false
      }
    }
  },

  methods: {
    handleUpdateSelectedDiagramTypes (types: Record<string, any>) {
      this.update({ availableDiagramTypes: types })
    },
    handleUpdateCategories (data: Record<string, any>) {
      if (data.type === 'updateTickAmount') {
        this.update({
          tickAmountData: { ...this.currentProperties.tickAmountData, current: data.value }
        })
      } else if (data.type === 'updateText') {
        const tsCategories = [...this.currentProperties.categories]
        tsCategories[data.index] = data.text

        this.update({
          labels: tsCategories,
          categories: tsCategories
        })
      }
    },
    handleUpdateLegend (data: Record<string, any>) {
      const tsLegend = [...this.currentProperties.legend]
      tsLegend[data.index] = data.text

      this.update({
        legend: tsLegend
      })
    },

    update (data: Record<string, any>) {
      this.customProperties = {
        ...this.customProperties,
        ...data
      }
    },

    updateEditOnly (data: Record<string, any>) {
      this.customEditOnlyProperties = {
        ...this.customEditOnlyProperties,
        ...data
      }
    },

    reset () {
      this.customProperties = {}
      this.customEditOnlyProperties = {}
      this.mutate('successfullyReset')
    },

    async save () {
      this.mutate('successfullyEdited')
    },

    async mutate (notificationParameter: string) {
      this.loading = true
      const newProperties = {
        ...this.trackingVisualizationProperties, // previous saved TrackingVisualizationProperties
        ...this.customProperties // changed Properties
      }
      await this.$apollo.mutate({
        mutation: UPDATE_TRACKING_VISUALIZATION,
        variables: {
          data: {
            id: this.trackingVisualization?.id,
            properties: JSON.stringify(newProperties)
          }
        }
      })

      this.$notification.publish('bottom', {
        message: this.$t('trackingVisualization.notification.' + notificationParameter),
        type: 'success',
        color: 'success'
      })

      this.loading = false
      this.$emit('saved')
    }
  },

  apollo: {
    run: {
      query: GET_RUN_TEXTS_ONLY,

      skip (): boolean {
        return !this.runId
      },

      variables (): object {
        return {
          id: this.runId
        }
      },

      update (data: Query): Run {
        return data.run!
      }
    },
    trackingVisualization: {
      query: TRACKING_VISUALIZATION,

      loadingKey: 'loadingTrackingVisualization',

      variables (): Record<string, any> {
        return {
          id: this.visualizationId
        }
      },

      update (result:Query): TrackingVisualization | null {
        if (!result?.trackingVisualization) return null
        return result.trackingVisualization
      }
    }
  }
})
