import { ApexOptions } from 'apexcharts'

export default {
  markers: { // BOOST PERFORMANCE
    size: 0
  },
  chart: {
    toolbar: {
      show: false
    },
    animations: {
    },
    zoom: {
      enabled: false
    }
  },

  grid: {
    show: true,
    borderColor: '#6d7579'
  },

  dataLabels: {
    enabled: false, // BOOST PERFORMANCE
    textAnchor: 'end'
  },
  legend: {
    show: false
  },
  tooltip: {
    theme: 'dark',
    followCursor: true
  },
  xaxis: {
    title: {
      text: undefined,
      style: {
        color: '#6d7579',
        fontSize: '16px'
      }
    },
    labels: {
      style: {
        fontSize: '14px'
      }
    }
  },

  plotOptions: {
    bar: {
      dataLabels: {
        position: 'top'
      }
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ['#888']
      },
      offsetX: 130
    }
  },
  yaxis: {
    title: {
      text: undefined,
      offsetX: -7,
      style: {
        color: '#6d7579',
        fontSize: '16px'
      }
    }
  } as ApexYAxis
} as ApexOptions
