import { ApexOptions } from 'apexcharts'
import deepmerge from 'deepmerge'
import { MappedTrackingDataArray } from '../types'
import getAnnotations from './utils/get-annotations'

export default function parse (
  mappedData: MappedTrackingDataArray,
  colors: string[],
  properties:Record<string, any>,
  labelColor: string
): ApexOptions {
  let series = null
  const seriesData = mappedData.map((data, index) => {
    if (properties.categories) {
      series = properties.categories.map((category:string, index:number) =>
        data.seriesData && data.seriesData[index] ? data.seriesData[index] : 0
      )
    } else {
      series = data.seriesData
    }

    return {
      data: series
    }
  })

  const coAnnotations = {
    annotations: {
      xaxis: getAnnotations(mappedData[0])
    }
  }

  const coChart = {
    chart: {
      type: 'line',
      animations: {
        enabled: true,
        easing: 'easeinout',
        dynamicAnimation: {
          enabled: true,
          speed: 450
        }
      }
    }
  }

  const coColors = {
    fill: {
      colors: colors
    },
    stroke: {
      colors: colors
    }
  }

  const coPlotOptions = {
    plotOptions: {
      bar: {
        distributed: true
      }
    }
  }

  const coSeries = {
    series: seriesData
  }

  const coStroke = {
    stroke: {
      curve: 'smooth',
      width: 5
    }
  }

  const coXaxis = {
    xaxis: {
      type: 'numeric',
      labels: {
        style: {
          colors: labelColor
        },
        rotate: 0 // no need to rotate since hiding labels gives plenty of room
        // hideOverlappingLabels: false // all labels must be rendered
      },
      tickAmount: properties.tickAmountData?.current
        ? properties.tickAmountData.current
        : 'dataPoints'
    }
  }

  const seriesYValues = seriesData[0].data?.length && Object.hasOwn(seriesData[0].data[0], 'y') ? seriesData[0].data.map(data => data.y) : seriesData[0].data as number[]
  const maxVotes = seriesYValues ? Math.max(...seriesYValues) : 0
  const minShownTicks = 2

  const coYaxis = {
    yaxis: {
      tickAmount: maxVotes > minShownTicks ? undefined : minShownTicks,
      max: maxVotes > minShownTicks ? undefined : minShownTicks,
      labels: {
        style: {
          colors: labelColor
        },
        formatter: function (value:number) {
          if (value !== Infinity) {
            return value.toFixed(0)
          }
        }
      }
    }
  }

  return deepmerge.all([
    coAnnotations,
    coChart,
    coColors,
    coPlotOptions,
    coSeries,
    coStroke,
    coXaxis,
    coYaxis
  ])
}
