


















































































import { Placement } from 'packages/core/types/graphql'
import Vue from 'vue'

export default Vue.extend({
  name: 'RankingList',

  props: {
    placements: Array as () => Placement[],
    selectedUserProperties: Array as () => string[]
  },

  data () {
    return {
      tableRowStyle: {
        'background-color': this.$vuetify.theme.isDark ? '#1c1c1c' : '#f5f5f5'
      }
    }
  },

  methods: {
    hasProperty (property: string) {
      return this.selectedUserProperties.includes(property)
    }
  }
})
