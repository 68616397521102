















import Vue from 'vue'

export default Vue.extend({
  name: 'SettingsRankingStyle',

  props: {
    rankingStyles: Array,
    rankingStyle: String
  },

  computed: {
    internalRankingStyle: {
      get (): number {
        return this.rankingStyles.indexOf(this.rankingStyle)
      },
      set (v: number) {
        this.$emit('input', { rankingStyle: this.rankingStyles[v] })
      }
    }
  }
})
