



































import Vue from 'vue'

type Medal = { value: string, text: string }
type Ranks = { first: Medal, second: Medal, third: Medal}

export default Vue.extend({
  name: 'RankingFlag',

  props: {
    rank: String,
    primaryText: String,
    secondaryText: String
  },

  data () {
    return {
      ranks: {
        first: {
          value: 'gold',
          text: '1.'
        },
        second: {
          value: 'silver',
          text: '2.'
        },
        third: {
          value: 'bronze',
          text: '3.'
        }
      } as Ranks,
      isDark: this.$vuetify.theme.isDark
    }
  },

  computed: {
    medal (): Medal {
      if (this.rank as keyof Ranks) {
        return this.ranks[this.rank as keyof Ranks]
      } else {
        return this.ranks.first
      }
    }
  }
})
