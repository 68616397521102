import Vue from 'vue'
import {
  REVOKE_SESSION,
  START_SESSION,
  UPDATE_SESSION,
  STORE_SESSION_TRACKING, ARCHIVE_SESSION_AND_RESTART
} from '@simpl/core/graphql'
import {
  Module,
  Run,
  Session,
  StoreSessionTrackingInput,
  SessionTrackingStatus,
  UpdateSessionInput
} from '@simpl/core/types/graphql'
import { MUTATIONS } from '../store/consts'
import { appCreated } from '@simpl/core/init-app'

export default Vue.extend({
  props: {
    module: Object as () => Module,
    run: Object as () => Run
  },

  data: () => ({
    session: null! as Session,
    loading: 0,
    restarting: false,
    previewMode: false
  }),

  computed: {
    trackingStatus (): SessionTrackingStatus {
      return this.session?.tracking_status || {}
    },
    sessionVisitedSites (): number[] {
      return this.trackingStatus.data?.visitedSites || []
    },
    sessionEvaluation (): Record<number, boolean> {
      return this.trackingStatus.data?.evaluation || {}
    },
    sessionQuestionIds (): number[] {
      return this.session?.data.questionIds || []
    },
    sessionStatus (): string | undefined | null {
      return this.trackingStatus.status
    },
    sessionEvaluated (): boolean {
      return this.trackingStatus.status === 'passed' || this.trackingStatus.status === 'failed'
    },
    sessionCompleted (): boolean {
      return this.trackingStatus.status === 'completed'
    },
    bookmark (): string | undefined | null {
      return this.trackingStatus.bookmark
    },
    attempt (): number | undefined {
      return this.$store.state.module.isTest ? this.session?.data.attempt || 1 : undefined
    }
  },

  async created () {
    if (!this.run || this.module.tracking_type === 'none' || !!this.$store.state.auth.impersonator) {
      this.previewMode = true
    }

    this.loading += 1
    await this.startSession()
    this.loading -= 1
  },

  methods: {
    async startSession (this: any) {
      if (this.previewMode) return

      const cachedSession = this.$store.getters['module/getSession'](this.run.id, this.module.id)
      const token = cachedSession ? cachedSession.token : null
      const data = cachedSession?.data || {} as any
      if (this.$store.state.auth.anonUsername) {
        data.anonUsername = this.$store.state.auth.anonUsername
      }

      try {
        const session = await this.$apollo.mutate({
          mutation: START_SESSION,
          variables: {
            data: {
              run_id: this.run.id,
              module_id: this.module.id,
              data: JSON.stringify(data)
            }
          },
          context: {
            headers: {
              simplsession: token
            }
          }
        })
        this.cacheSessionData(session.data.startSession)

        this.afterSessionCreated()
      } catch (e) {
        this.session = null
        console.error(e)
      }
    },

    async updateSession (this: any, data: Omit<UpdateSessionInput, 'token'>) {
      if (this.previewMode) return

      try {
        const session = await this.$apollo.mutate({
          mutation: UPDATE_SESSION,
          variables: {
            data: {
              token: this.session.token,
              ...data
            }
          },
          context: {
            headers: {
              simplsession: this.session.token
            }
          }
        })

        this.cacheSessionData(session.data.updateSession)
      } catch (e) {
        console.error(e)
      }
    },

    async revokeSession (this: any) {
      if (this.previewMode) return

      await this.$apollo.mutate({
        mutation: REVOKE_SESSION,
        variables: {
          token: this.session.token
        },
        context: {
          headers: {
            simplsession: this.session.token
          }
        }
      })
      this.cacheSessionData(null!)
    },

    async archiveAndRestartSession (this: any) {
      if (this.previewMode) return
      this.restarting = true

      try {
        const session = await this.$apollo.mutate({
          mutation: ARCHIVE_SESSION_AND_RESTART,
          variables: {
            token: this.session.token
          },
          context: {
            headers: {
              simplsession: this.session.token
            }
          }
        })
        this.cacheSessionData(session.data.archiveSessionAndRestart)
        appCreated(true).then(() => {
          this.restarting = false
        })
      } catch (e) {
        console.log(e)
      }
    },

    async storeSessionTracking (this: any, input: Partial<Omit<StoreSessionTrackingInput, 'token'>>) {
      if (this.previewMode || !this.session) return

      const session = await this.$apollo.mutate({
        mutation: STORE_SESSION_TRACKING,
        variables: {
          data: {
            ...input,
            token: this.session.token
          }
        },
        context: {
          headers: {
            simplsession: this.session.token
          }
        }
      })

      this.cacheSessionData(session.data.storeSessionTracking)
    },

    async markSessionCompleted () {
      if (this.previewMode) return

      await this.storeSessionTracking({
        tracking_status: {
          status: 'completed',
          progress: 100
        }
      })
    },

    afterSessionCreated () {},

    cacheSessionData (session: Session) {
      this.session = session
      this.$store.commit(
        `module/${MUTATIONS.SET_SESSION}`,
        { session: session, runId: this.run.id, moduleId: this.module.id }
      )
    }
  }
})
