




























































































































































































































































































































































































































































import mixins from 'vue-typed-mixins'
import { LIST_MODULES, DELETE_MODULE } from '../graphql'
import EditModuleDialog from '../components/EditModuleDialog.vue'
import ItemTile from '../../components/ItemTile.vue'
import ModuleFormats from '@simpl/core/mixins/utils/ModuleFormats'
import { getTextForUserLanguage, getTextForTemporaryUserLanguage } from '@simpl/core/utils/text'
import { stringToDate } from '@simpl/core/utils/core'
import UserSettings from '@simpl/core/mixins/utils/UserSettings'
import { MUTATIONS } from '@simpl/auth/store/consts'
import FilterView from '@simpl/core/components/FilterView.vue'
import { moduleFormats } from '@simpl/core/utils/package-formats'
import StringToColor from '@simpl/core/mixins/utils/StringToColor'
import FallbackLanguageCheck from '@simpl/core/mixins/utils/FallbackLanguageCheck'
import ImportContentDialog from '@simpl/core/components/dialogs/ImportContentDialog.vue'
import TagSelectFilter from '@simpl/core/mixins/utils/TagSelectFilter'
import { FilterColumn, Module, Query, Tag } from '@simpl/core/types/graphql'
import { DataTableHeader } from '@simpl/core/types/table'
import { TagWithName } from '@simpl/core/types/extended-types'

type ModuleExtended = Module & {
  name: string,
  // eslint-disable-next-line camelcase
  updated_at: string
}

function remapEntry (entry: Module) {
  return {
    ...entry,
    name: getTextForTemporaryUserLanguage(entry),
    updated_at: entry.updated_at ? stringToDate(entry.updated_at) : entry.updated_at
  }
}

export default mixins(ModuleFormats,
                      StringToColor,
                      FallbackLanguageCheck,
                      TagSelectFilter,
                      UserSettings('moduleListView', [
                        'sortBy',
                        'sortDesc',
                        'selectedView',
                        'itemsPerPage',
                        'temporaryLanguage'
                      ])).extend({
  name: 'ModuleListView',

  components: {
    EditModuleDialog,
    ItemTile,
    FilterView,
    ImportContentDialog
  },

  data () {
    return {
      moduleFormats,
      /** Displayed data */
      modules: [] as Module[],
      loading: 0,

      showContentDialog: false,
      moduleContent: null,

      /** Dialog control flow */
      dialog: false,
      selected: null,
      editing: false,

      filterBy: [] as FilterColumn[],
      showFilter: false,

      /** Search and filter */
      searchValue: '',
      sortBy: [] as any[],
      sortDesc: [] as any[],
      count: 1,
      page: 1,
      itemsPerPage: 10,

      selectedView: 'list',
      temporaryLanguage: '',
      showImportContentDialog: false
    }
  },

  computed: {
    headers (): DataTableHeader[] {
      return [{
        text: this.$t('core.global.name'),
        value: 'name'
      }, {
        text: this.$t('core.global.type'),
        value: 'type'
      }, {
        text: this.$t('core.global.trackingType'),
        value: 'tracking_type'
      }, {
        text: this.$t('core.global.languages'),
        value: 'languages',
        sortable: false
      }, {
        text: this.$t('core.global.duration'),
        value: 'duration'
      }, {
        text: this.$t('core.global.updatedAt'),
        value: 'updated_at'
      }, {
        text: '',
        value: 'actions-cms',
        class: 'separate-right',
        width: 15
      }, {
        text: '',
        value: 'actions',
        class: 'separate-left',
        width: 140,
        sortable: false
      }]
    },
    presentedData (): ModuleExtended[] {
      return this.modules.slice().map(remapEntry)
    },
    computedHeaders (): DataTableHeader[] {
      return this.headers.filter(header => !(this.$vuetify.breakpoint.xs && header.value === 'actions'))
    },
    queryVariables (): Record<string, any> {
      return {
        filter: {
          search: {
            query: this.searchValue,
            columns: ['texts:display_name']
          },
          filterBy: this.filterBy
        },
        orderBy: this.sortBy.map((key, index) => ({
          column: this.sortBy[index],
          order: this.sortDesc[index] ? 'DESC' : 'ASC'
        })),
        page: this.page,
        first: this.itemsPerPage
      }
    },
    pagination (): Record<string, any> {
      return {
        page: this.page,
        itemsLength: this.count
      }
    },
    languageTags (): TagWithName[] {
      return this.$store.state.auth.user.tags
        .filter((tag: Tag) => tag.category!.identifier === 'language')
        .map((tag: Tag) => {
          return {
            ...tag,
            name: getTextForUserLanguage(tag)
          }
        })
    },
    filterOptions (): Record<string, any> {
      return [
        {
          type: 'chip-group',
          props: {
            headline: 'core.global.type',
            items: this.moduleFormats.map(format => {
              return {
                id: format.key,
                identifier: format.key,
                name: this.$t(`core.contentTypes.${format.key}`)
              }
            })
          },
          filterColumn: 'values',
          model: 'type'
        },
        {
          type: 'date-range',
          props: {
            headline: 'core.global.updatedAt'
          },
          filterColumn: 'between',
          model: 'updated_at'
        }
      ]
    }
  },

  watch: {
    searchValue () {
      this.page = 1
    },
    temporaryLanguage (v) {
      this.$store.commit(`auth/${MUTATIONS.SET_TEMPORARY_LANGUAGE}`, { temporaryLanguage: v })
    }
  },

  apollo: {
    modules: {
      query: LIST_MODULES,

      variables (): Record<string, any> {
        return this.queryVariables
      },

      update (result: Query): Module[] {
        const { total, currentPage, perPage } = result.modules!.paginatorInfo
        this.count = total
        this.page = currentPage
        this.itemsPerPage = perPage

        return result.modules!.data
      },

      error (error) {
        console.error(error)
      },

      loadingKey: 'loading'

      /* subscribeToMore: {
        document: null,

        variables () {
          return this.queryVariables
        },

        updateQuery () {
          this.$apollo.queries.modules.refetch()
        }
      } */
    }
  },

  methods: {
    handleRowClick (item: ModuleExtended) {
      this.$router.push(`/content/${item.id}/edit/basic`)
    },
    updateSearchValue (v: string) {
      this.searchValue = v
    },

    getMutationVariables () {
      return {
        identifier: 'New Module',
        type: 'generic'
      }
    },

    getModuleLanguageVariants (module: ModuleExtended) {
      return module.properties?.languageVariants || []
    },

    add () {
      this.selected = null
      this.editing = false
      this.dialog = true
    },
    async remove (id: string, name: string) {
      const answer = await this.$confirm({
        color: 'error',
        message: this.$t('module.message.deleteConfirm', [name]),
        buttons: [{
          text: this.$t('core.action.cancel'),
          type: 'outlined',
          answer: false
        }, {
          text: this.$t('core.action.delete'),
          color: 'error',
          answer: true
        }]
      })

      if (!answer) return

      this.loading += 1
      await this.$apollo.mutate({
        mutation: DELETE_MODULE,
        variables: {
          id
        }
      })
      await this.$apollo.queries.modules.refetch()
      this.loading -= 1
    },
    refetchModules () {
      this.$apollo.queries.modules.refetch()
    },
    async onImport () {
      await this.$apollo.queries.modules.refetch()
    }
  }
})
