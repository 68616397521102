
















import Vue from 'vue'
import TrackingVisualizationWizard from './TrackingVisualizationWizard.vue'
import { RUN_EVALUATION } from '../graphql'
import { getTextForTemporaryUserLanguage } from '@simpl/core/utils/text'
import { stringToDate } from '@simpl/core/utils'
import { Query, RunEvaluation } from '@simpl/core/types/graphql'

export default Vue.extend({
  name: 'TrackingVisualizationPresent',

  breadcrumbs (): any[] {
    const text = this.$router.currentRoute.meta?.breadcrumbs?.text
    const breadcrumbs = []

    if (text) {
      breadcrumbs.push({
        text: text,
        to: null
      })
    }

    return breadcrumbs
  },

  components: {
    TrackingVisualizationWizard
  },

  props: {
    runIdentifier: String,
    evaluationIdentifier: String
  },

  data: () => ({
    loading: 0,
    evaluation: {
      trackingVisualizations: []
    }
  }),

  /*
  TODO @cb:
  In RunEditControlCenter wird auf die Subscription SESSION_TRACKING_STORED gehört und die Daten verarbeitet.
  Die Subscription gibt pro run_id und module_id die jeweiligen aktuell gespeicherten Trackings in Echtzeit zurück.
  Das enthält dann auch die trackings. Die müssten dann zum Graph hinzugefügt werden um ein Echtzeit-Update zu erhalten.
   */

  apollo: {
    evaluation: {
      query: RUN_EVALUATION,

      loadingKey: 'loadingEvaluations',

      variables (): Record<string, any> {
        return {
          id: this.evaluationIdentifier
        }
      },

      update (result: Query): RunEvaluation {
        if (!result?.runEvaluation) return null!

        // FIXME: check file typings
        return {
          ...result.runEvaluation!,
          trackingVisualizations: (result.runEvaluation.trackingVisualizations || []).map((this as any).remapEntry),
          properties: { ...(result.runEvaluation.properties || {}) }
        }
      }
    }
  },
  methods: {
    remapEntry (entry: any): Record<string, any> {
      let name = getTextForTemporaryUserLanguage(entry)
      if (name === 'display_name') {
        name = entry.identifier
      }

      return {
        ...entry,
        name: name,
        items: entry.items,
        created_at: entry.created_at ? stringToDate(entry.created_at) : entry.created_at,
        updated_at: entry.updated_at ? stringToDate(entry.updated_at) : entry.updated_at
      }
    },

    async refetch () {
      await this.$apollo.queries.evaluation.refetch()
    }
  }
})
