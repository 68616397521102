















import Vue from 'vue'

export default Vue.extend({
  name: 'SettingsOrientation',

  props: {
    orientations: Array,
    orientation: String
  },

  computed: {
    internalOrientation: {
      get (): number {
        return this.orientations.indexOf(this.orientation)
      },
      set (v) {
        this.$emit('input', { orientation: this.orientations[v] })
      }
    }
  }
})
