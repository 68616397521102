





























































































import mixins from 'vue-typed-mixins'
import Session from '../../mixins/Session'
import { ModuleLink, Tag } from '@simpl/core/types/graphql'
import { IE } from '@simpl/core/utils'
import store from '@simpl/core/plugins/store'

export default mixins(Session).extend({
  name: 'ModuleExternalViewer',

  data: () => ({
    show: false,
    contentLoaded: false
  }),

  computed: {
    moduleLink (): ModuleLink {
      const userLanguage = this.$store.state.auth.temporaryLanguage || this.$store.state.auth.user.languagecode
      const marketTags = store.state.auth?.user?.tags?.filter((tag: Tag) => tag.category!.identifier === 'market')
      const marketLanguage = store.state.auth?.domain?.markets && marketTags.length
        ? `${userLanguage}-${marketTags[0].identifier}`
        : userLanguage
      return this.module.links!.find(p => p!.languagecode === marketLanguage) ||
        this.module.links!.find(p => p!.languagecode === userLanguage) ||
        this.module.links!.find(p => p!.is_master)!
    }
  },

  watch: {
    session () {
      this.show = true
    }
  },

  methods: {
    backOrClose () {
      if (history.length > 1) {
        this.$router.back()
      } else {
        window.close()
      }
    },
    openLink () {
      window.open(
        this.moduleLink.url!,
        undefined
      )

      this.markSessionCompleted()
    }
  },

  mounted () {
    if (this.previewMode) {
      this.show = true
    }
  }
}
)
