import { ApexOptions } from 'apexcharts'
import deepmerge from 'deepmerge'
import { MappedTrackingDataArray } from '../types'
import { app } from '@simpl/core/init-app'

interface XYCoordinates {
  x: string,
  y: number | string
}

function instanceOfXYCoordinates (object: any): object is XYCoordinates {
  return object.x !== undefined && object.y !== undefined
}

export default function parse (
  type: String,
  mappedData: MappedTrackingDataArray,
  colors: string[],
  properties:Record<string, any>
): ApexOptions {
  let seriesData

  const firstItem = mappedData[0].seriesData?.[0]
  if (firstItem && instanceOfXYCoordinates(firstItem)) {
    seriesData = mappedData.map(data => data.seriesData?.map(data => data.y))
  } else {
    seriesData = mappedData.map(data => data.seriesData)
  }
  const firstSet = seriesData?.[0] || []

  // Zeros cause problems when switching between pie and line charts
  const seriesWithoutZeros = firstSet!.filter(val =>
    typeof val === 'number' && val !== 0
  )
  const labelsWithoutZeros = properties.categories!.filter((val: any, index: number) => {
    const firstSetValue = firstSet[index]
    return typeof firstSetValue === 'number' && firstSetValue !== 0
  })

  const coChart = {
    chart: {
      type: type,
      animations: {
        enabled: false,
        easing: 'easeinout',
        dynamicAnimation: {
          enabled: true,
          speed: 450
        }
      }
    }
  }

  const activeColors = [] as string[]
  firstSet!.forEach((val, index) => {
    if (typeof val === 'number' && val !== 0) {
      activeColors.push(colors[index % colors.length])
    }
  })

  const coColors = {
    colors: colors,
    fill: {
      colors: activeColors
    },
    dataLabels: {
      style: {
        colors: activeColors
      }
    },
    stroke: {
      colors: activeColors
    }
  }

  const coLegend = {
    legend: {
      show: true,
      position: 'bottom',
      fontSize: '18px',
      customLegendItems: properties.categories,
      labels: {
        colors: '#9e9e9',
        useSeriesColors: false
      },
      markers: {
        fillColors: colors
      }
    }
  }

  const coPlotOptions = {
    plotOptions: {
      pie: {
        donut: {
          size: '65%',
          background: 'transparent',
          labels: {
            show: type === 'donut',
            colors: activeColors,
            name: {
              show: true,
              fontSize: '24px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 600,
              color: '#9e9e9e',
              offsetY: 45
            },
            value: {
              show: true,
              fontSize: '64px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
              color: 'white',
              offsetY: 0,
              formatter: function (val:string) {
                let count = 0
                let text
                for (let i = 0; i < seriesWithoutZeros.length; i++) {
                  count += seriesWithoutZeros[i]
                }
                const percent = 100 * parseInt(val) / count
                if (percent < 1) {
                  text = '< 1%'
                } else {
                  text = String(Math.round(percent)) + '%'
                }
                return text
              }
            }
          }
        }
      }
    }
  }

  const coSeries = {
    series: seriesWithoutZeros,
    labels: labelsWithoutZeros
  }

  const coStroke = {
    stroke: {
      width: 0
    }
  }

  const coTooltip = {
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }:
        { series:Record<string, any>, seriesIndex:number, dataPointIndex: number, w: Record<string, any> }) {
        const currentSeries = series
        let count = 0
        const value = currentSeries[seriesIndex]
        const labelText = labelsWithoutZeros[seriesIndex]
        const valueText = value === 1
          ? `${value} ${app.$t('trackingVisualization.chart.vote')}`
          : `${value} ${app.$t('trackingVisualization.chart.votes')}`

        let percentText
        for (let i = 0; i < currentSeries.length; i++) {
          count += parseInt(currentSeries[i])
        }
        const percent = 100 * value / count
        if (percent < 1) {
          percentText = '< 1%'
        } else {
          percentText = String(Math.round(percent)) + '%'
        }
        return (
          '<div class="parser-tooltip">' +
          '<div class="label">' + labelText + '</div>' +
          '<div class="value">' + valueText + '</div>' +
          '<div class="percent">' + percentText + '</div>' +
          '</div>'
        )
      }
    }
  }

  return deepmerge.all([
    coChart,
    coColors,
    coSeries,
    coLegend,
    coPlotOptions,
    coStroke,
    coTooltip
  ])
}
