/* eslint-disable camelcase */

import { SessionTrackingStatus, User } from '@simpl/core/types/graphql'
import i18n from '@simpl/core/plugins/i18n'

/**
 * @deprecated
 * @param tracking
 * @param user
 */
export function trackingDataToCMI (
  tracking: SessionTrackingStatus | undefined | null,
  user: User
): Record<string, any> {
  const retVal = {
    core: {
      lesson_location: tracking?.bookmark || null,
      score: tracking?.data?.score || '',
      student_id: user.id,
      student_name: i18n.t('core.global.fullname', [user.firstname, user.lastname]) as string,
      session_time: tracking?.data?.session_time || '00:00:00'
    },
    suspend_data: tracking?.data?.suspend_data
  } as Record<string, any>

  if (tracking?.data?.lesson_status) {
    retVal.core!.lesson_status = tracking?.data.lesson_status
  }

  if (tracking?.objectives) {
    retVal.objectives = tracking?.objectives
  }

  if (tracking?.data?.preferences) {
    retVal.student_preference = tracking?.data.preferences
  }

  return retVal
}
