


























































import Vue from 'vue'
import { ContentTree } from '../../../../cms/types'
import { getTextForUserLanguage } from '@simpl/core/utils'
import { MUTATIONS } from '../../../store/consts'

export default Vue.extend({
  props: {
    content: {
      type: String,
      default: ''
    },
    index: Number,
    backgroundOpacity: {
      type: Number,
      default: 0.3
    }
  },

  computed: {
    internalTitle: {
      get (): string {
        // console.log('in StructureItem: this.content ->', this.content)
        return this.content
      },
      set (v: string): void {
        this.$emit('input', { text: v, index: this.index })
      }
    }
  }
})
